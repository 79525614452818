import { useState, useEffect, useMemo } from "react";
import { useState as useHookState } from "@hookstate/core";
import Cookies from 'js-cookie';
import CustomTable from "../../../shared/CustomTable";
import GlobalStates from '../../../../utils/GlobalStates';
import ConstantStyles from "../../../../styles/ConstantStyles.json";
import CustomButton from "../../../shared/CustomButton";
import KycTabs from "../../../../utils/KycTabs";
import Setting from "./Forms/Settings";
import Phone from "./Forms/Phone";
import Password from "./Forms/Password";
import Wallet from "./Forms/Wallet";
import moment from "moment";
import {
    Grid,
    Card,
    CardContent,
} from '@mui/material';
import { GetKYC, GetTransactionsWithdraw, GetTransactionsDeposit, GetGameHistory } from "../../../../utils/Endpoints";



const KYC = () => {
    const globalStates = useHookState(GlobalStates);
    const [activeTab, setActiveTab] = useState("0");
    const [player, setPlayer] = useState([]);
    const [refresh, setRefresh] = useState("");
    const [withdrawPagination, setWithdrawPagination] = useState("");
    const [withdrawSize, setWithdrawSize] = useState("");
    const [depositPagination, setDepositPagination] = useState("");
    const [depositSize, setDepositSize] = useState("");
    const [depositList, setDepositList] = useState([]);
    const [withdrawList, setWithdrawList] = useState([]);
    const [gameHistory, setGameHistory] = useState([]);
    const [historyList, setHistoryList] = useState("game")

    useEffect(() => {
        GetDetails()
        GetGame()
    }, [refresh]);

    const pull_data = (data) => {
        setRefresh(data)
    }

    const handleList = (data) => {
        setHistoryList(data)
    }


    const GetDetails = () => {
        GetKYC()
            .then(response => {
                setPlayer(response.data.data)
                let withdraw_body = {
                    "page": withdrawPagination,
                    "size": withdrawSize
                }
                GetTransactionsWithdraw(withdraw_body)
                    .then(response => {

                        let witdrawLists = response.data.data.map(emp => {
                            return {
                                account: emp.settlement_id + " :" + emp.settlement_account_name + " " + emp.settlement_account_number ?? "-",
                                amount: emp.amount ?? "-",
                                status: emp.status ?? "-",
                                create_date: moment(emp.create_date).format('MMM. DD, YYYY h:mm:ss a') ?? "-"
                            }
                        });

                        setWithdrawList(witdrawLists)
                    })

                    .catch(error => {
                        console.log(error)
                        globalStates.alertType.set("error");
                        globalStates.alertTitle.set("Error");
                        globalStates.alertBody.set("Failed to retrieve withdraw history.");
                        globalStates.showAlert.set(true);

                        setTimeout(() => {
                            globalStates.showAlert.set(false);
                        }, 3000);
                    })

                let deposit_body = {
                    "page": depositPagination,
                    "size": depositSize
                }
                GetTransactionsDeposit(deposit_body)
                    .then(response => {

                        let depositLists = response.data.data.map(emp => {
                            return {
                                account: emp.pay_ref ?? "-",
                                amount: emp.amount ?? "-",
                                status: emp.status ?? "-",
                                create_date: moment(emp.create_date).format('MMM. DD, YYYY h:mm:ss a') ?? "-"
                            }
                        });

                        setDepositList(depositLists)
                    })

                    .catch(error => {
                        console.log(error)
                        globalStates.alertType.set("error");
                        globalStates.alertTitle.set("Error");
                        globalStates.alertBody.set("Failed to retrieve deposit history.");
                        globalStates.showAlert.set(true);

                        setTimeout(() => {
                            globalStates.showAlert.set(false);
                        }, 3000);
                    })
            })
            .finally(() => {
                // setIsTableLoading(false);
            });
    }

    const GetGame = () => {
        GetGameHistory()
            .then(response => {
                console.log(response.data.data.data)
                let newResponse = response.data.data.data;
                let list = newResponse.map(emp => {
                    return {
                        game_id: emp.game_id ?? "-",
                        amount: emp.amount ?? "-",
                        amount_won: emp.amount_won ?? "-",
                        create_date: moment(emp.create_date).format('MMM. DD, YYYY h:mm:ss  a') ?? "-"
                    }
                });

                setGameHistory(list)
            })
            .catch(error => {
                console.log(error)
            })
    }
    const handleChange = (event, newValue) => {
        setActiveTab(event.target.id);
    };

    const handleLogout = () => {
        localStorage.clear()
        Cookies.set('session-1', "")
        globalStates.alertBody.set("Logging out ...");
        globalStates.alertType.set("success");
        globalStates.alertTitle.set("Success");
        globalStates.showAlert.set(true);

        setTimeout(() => {
            window.location.href = "/";
        }, 2000);
    };

    const getTableHeaders = useMemo(() => {
        return [
            { id: "account", name: "Details", sortable: true },
            { id: "amount", name: "Amount", sortable: true },
            { id: "status", name: "Status", sortable: true },
            { id: "create_date", name: "Date", sortable: true }
        ];
    }, []);


    const getTableHeadersGame = useMemo(() => {
        return [
            { id: "game_id", name: "Game ID", sortable: true },
            { id: "amount", name: "Amount", sortable: true },
            { id: "amount_won", name: "Amount Won", sortable: true },
            { id: "create_date", name: "Date", width: "40%", sortable: true }
        ];
    }, []);

    return (
        <Grid container justifyContent="" spacing={2} p={{ xs: 2, sm: 4 }} style={{ backgroundColor: ConstantStyles['light-grey'], paddingTop: "0px", paddingBottom: "30px" }} >

            <Grid item xs={12} sm={6}>
                <Card sx={{ maxWidth: "100%" }} style={{
                    backgroundColor: ConstantStyles.white,
                    color: ConstantStyles['dark-grey'], boxShadow: ConstantStyles['default-box-shadow2'], borderRadius: "12px",
                }}>
                    <CardContent
                        style={{ minHeight: "175px" }}
                    >
                        <h2 style={{ display: "inline-block", }}>{localStorage.getItem('username')}</h2>
                        <p style={{ display: "inline-block", float: "right" }}>
                            <CustomButton
                                buttonLabel={"Logout"}
                                onClick={() => handleLogout()}
                                type="submit"
                                customSX={{
                                    width: "100%",
                                    padding: "5px",
                                    color: ConstantStyles.black,
                                    border: ConstantStyles['default-border'],
                                    backgroundColor: ConstantStyles.white,
                                    verticalAlign: "top",
                                    "&:hover": {
                                        backgroundColor: ConstantStyles.primary,
                                        color: ConstantStyles.white,
                                        boxShadow: "none"
                                    },
                                }}
                            /></p>
                        <p>Name: <b>{player.first_name ?? "-"} {player.middle_name ?? "-"} {player.last_name ?? "-"}</b></p>
                        <p>Birthday: <b>{player.birth_date ?? "-"}</b></p>
                        <p>Mobile #: <b>{player.mobile_no ?? "-"}</b></p>
                        <p>Email: <b>{player.email ?? "-"}</b></p>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Card sx={{ maxWidth: "100%" }} style={{
                    backgroundColor: ConstantStyles.white,
                    color: ConstantStyles['dark-grey'], boxShadow: ConstantStyles['default-box-shadow2'], borderRadius: "12px"
                }}>
                    <CardContent
                        style={{ minHeight: "175px" }}
                    >
                        <Wallet func={pull_data} />

                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} sm={6}>
                <Card sx={{ maxWidth: "100%" }} style={{
                    backgroundColor: ConstantStyles.white,
                    color: ConstantStyles['dark-grey'], boxShadow: ConstantStyles['default-box-shadow2'], borderRadius: "12px"
                }}>
                    <CardContent
                        sx={{ minHeight: "790px", maxHeight: { xs: "790px", sm: "790px" }, overflowY: "scroll", height: "300px" }}
                    >
                        <Grid container justifyContent={"center"} p={2} spacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }} pr={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }} pl={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}>
                            {KycTabs.map((text, index) => (

                                <Grid item xs={12} sm={6} md={6} lg={2.4}
                                    key={index}
                                    sx={{
                                        textAlign: { xs: "left", sm: "cente" }
                                    }}
                                    style={{
                                        padding: "10px 15px", cursor: "pointer", borderBottom: parseInt(activeTab) === parseInt(index) ? text.borderBottom : "2px solid #eee"
                                    }}
                                >
                                    <div id={index} onClick={handleChange}>
                                        {parseInt(activeTab) === parseInt(index) ?
                                            <>
                                                <p style={{
                                                    pointerEvents: "none",
                                                    fontSize: "16px",
                                                    textAlign: "center",
                                                    marginBottom: "0px",
                                                    color: text.color_active,
                                                }}>{text.title}</p>
                                            </>
                                            :
                                            <>
                                                <p style={{
                                                    fontSize: "16px",
                                                    textAlign: "center",
                                                    margin: 0,
                                                    pointerEvents: "none",
                                                    marginBottom: "0px",
                                                    color: text.color
                                                }}>{text.title}</p>
                                            </>}
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                        <br />
                        {activeTab === "0" ?
                            <Setting func={pull_data} player={player} type="notid" />

                            : null}
                        {activeTab === "1" ?
                            <Setting func={pull_data} player={player} type="id" />

                            : null}
                        {activeTab === "2" ?
                            <Phone type="Phone" func={pull_data} player={player} />
                            : null}
                        {activeTab === "3" ?
                            <Phone type="Email" func={pull_data} player={player} />

                            : null}
                        {activeTab === "4" ?
                            <Password player={player} />

                            : null}


                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} sm={6}>
                <Card sx={{ maxWidth: "100%" }} style={{
                    backgroundColor: ConstantStyles.white,
                    color: ConstantStyles['dark-grey'], boxShadow: ConstantStyles['default-box-shadow2'], borderRadius: "12px"
                }}>
                    <CardContent
                        style={{ minHeight: "790px", maxHeight: "790px" }}
                    >

                        <Grid container justifyContent="center" spacing={0} p={{ xs: 1, sm: 0 }} mb={3}>

                            <Grid item xs={12} sm={12}>
                                <h2 style={{ textAlign: "left" }}>Transaction History</h2>
                                <hr />
                            </Grid>
                            <Grid item xs={4} sm={4}
                                onClick={() => handleList("game")}>
                                <p
                                    style={{
                                        textAlign: "center", cursor: "pointer",
                                        fontSize: "16px",
                                        fontWeight: historyList === "game" ? "bold" : "normal"
                                    }}
                                >Game</p>
                            </Grid>

                            <Grid item xs={4} sm={4}
                                onClick={() => handleList("deposit")}>
                                <p
                                    style={{
                                        textAlign: "center", cursor: "pointer",
                                        fontSize: "16px",
                                        fontWeight: historyList === "deposit" ? "bold" : "normal"
                                    }}
                                >Deposit</p>
                            </Grid>
                            <Grid item xs={4} sm={4}
                                onClick={() => handleList("withdraw")}>
                                <p
                                    style={{
                                        textAlign: "center", cursor: "pointer",
                                        fontSize: "16px",
                                        fontWeight: historyList === "withdraw" ? "bold" : "normal"
                                    }}
                                >Withdraw</p>
                            </Grid>

                        </Grid>
                        {historyList === "deposit" ?
                            <CustomTable
                                headers={getTableHeaders}
                                data={depositList}
                                order={"desc"}
                                // orderBy={"userId"}
                                maxHeight="550px"
                                minHeight="550px"
                                noDataText={"No Record(s) Found"}
                            />
                            : null}

                        {historyList === "withdraw" ?
                            <CustomTable
                                headers={getTableHeaders}
                                data={withdrawList}
                                maxHeight="550px"
                                minHeight="550px"
                                order={"desc"}
                                // orderBy={"userId"}
                                noDataText={"No Record(s) Found"}
                            />
                            : null}
                        {historyList === "game" ?
                            <CustomTable
                                headers={getTableHeadersGame}
                                data={gameHistory}
                                maxHeight="550px"
                                minHeight="550px"
                                order={"desc"}
                                // orderBy={"userId"}
                                noDataText={"No Record(s) Found"}
                            />
                            : null}

                        {/* depositList
                        withdrawList */}
                    </CardContent>
                </Card>
            </Grid>

        </Grid >
    )


}


export default KYC;