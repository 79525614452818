import { createTheme } from "@mui/material/styles";
import ConstantStyles from "./ConstantStyles.json";

const baseTheme = createTheme({
    palette: {
        primary: {
            main: ConstantStyles.primary
        },
        secondary: {
            main: ConstantStyles.secondary
        },
        cancel: {
            main: ConstantStyles.white,
            contrastText: ConstantStyles["dark-grey"],
            dark: "rgba(0, 0, 0, 0.1)"
        },
        plain: {
            main: ConstantStyles.white,
            contrastText: ConstantStyles["default-text-color"],
            dark: "#e8e8e8"
        },
        text: {
            primary: ConstantStyles["default-text-color"]
        }
    },
    typography: {
        fontFamily: "Poppins",
        fontSize: 14
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    borderRadius: ConstantStyles["default-border-radius"]
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    color: ConstantStyles["default-text-color"]
                },
                head: {
                    color: ConstantStyles.primary,
                    fontWeight: 600
                }
            }
        },
        MuiTableSortLabel: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        color: ConstantStyles.primary,
                    },
                    "&.Mui-active": {
                        color: ConstantStyles.primary
                    }
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    color: ConstantStyles["default-text-color"],
                    backgroundColor: ConstantStyles["light-grey"],
                    borderRadius: "5px 5px 0px 0px",
                    '&.Mui-selected': {
                        color: ConstantStyles.primary,
                        backgroundColor: ConstantStyles.white,
                        fontWeight: 700
                    }
                }
            }
        }
    }
});

export default baseTheme;