import { useState, useEffect } from "react";
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from '../../../../../utils/GlobalStates';
import ConstantStyles from "../../../../../styles/ConstantStyles.json";
import CustomTextField from "../../../../shared/CustomTextField";
import CustomButton from "../../../../shared/CustomButton";
import {
    Grid,
    Card,
    CardContent,
} from '@mui/material';
import { SendOTP, UpdateKYC } from "../../../../../utils/Endpoints";

const Phone = (props) => {
    const globalStates = useHookState(GlobalStates);
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [contact, setContact] = useState(props.type === "Email" ? props?.player.email ?? "" : props?.player.mobile_no);
    const [otp, setOtp] = useState("");
    const [expectOTP, setExepectOTP] = useState("");

    useEffect(() => {
        setContact(props.type === "Email" ? props?.player.email ?? "" : props?.player.mobile_no)
    }, [props]);

    const handleUpdate = () => {

        if (expectOTP === otp) {
            let body;
            if (props.type === "Email") {
                body = {
                    "username": props?.player.username,
                    "data": {
                        "email": contact,
                    }
                }
            }
            else {
                body = {
                    "username": props?.player.username,
                    "data": {
                        "mobile_no": contact,
                    }
                }

            }
            UpdateKYC(body)
                .then(response => {
                    if (response.data.status) {
                        globalStates.alertBody.set("Detail(s) Successfully updated");
                        globalStates.alertType.set("success");
                        globalStates.alertTitle.set("Success");
                        globalStates.showAlert.set(true);
                    }

                })
                .catch(error => {
                    console.log(error)
                    globalStates.alertType.set("error");
                    globalStates.alertTitle.set("Error");
                    globalStates.alertBody.set("Failed to retrieve player details.");
                    globalStates.showAlert.set(true);

                    setTimeout(() => {
                        globalStates.showAlert.set(false);
                    }, 3000);
                })
                .finally(() => {
                    // setIsTableLoading(false);
                    props.func(Date.now());
                });
        }
        else {
            globalStates.alertBody.set("Invalid OTP. Kindly check / request again");
            globalStates.alertType.set("error");
            globalStates.alertTitle.set("Error");
            globalStates.showAlert.set(true);

        }
        setTimeout(() => {
            globalStates.showAlert.set(false);
        }, 2000);
    }

    const handleSendOTP = () => {
        let isEmail = /^\S+@\S+\.\S+$/.test(contact);
        let isMobile = isEmail ? false : contact.length == 11 && contact[0] == 0 && Number(contact);
        let type = null;
        if (isEmail) {
            type = "email";
            globalStates.alertBody.set("Phone Number Successfully updated");
            globalStates.alertType.set("success");
            globalStates.alertTitle.set("Success");
            let body = {
                "type": type,
                "data": {
                    "username": contact,
                    "type": "update"
                }
            }
            submitOTP(body)
        }
        else if (isMobile) {
            if (isMobile) {
                type = "mobile";
                let body = {
                    "type": type,
                    "data": {
                        "username": contact,
                        "type": "update"
                    }
                }
                submitOTP(body)
            }
        }
        else {
            globalStates.alertBody.set("Invalid Format, Kindly choose between mobile number and email.");
            globalStates.alertType.set("error");
            globalStates.alertTitle.set("Error");
        }

        globalStates.showAlert.set(true);

    }

    const submitOTP = (body) => {
        SendOTP(body)
            .then(response => {
                // console.log(response.data.data.send)
                globalStates.alertBody.set("Successfully sent an OTP. Kindly check your phone");
                globalStates.alertType.set("success");
                globalStates.alertTitle.set("Success");
                globalStates.showAlert.set(true);
                setExepectOTP(response.data.data.send)
            })
            .catch(error => {
                // console.log(error);
                setIsLoggingIn(false);

                if ((error?.response?.data?.message ?? "").toLowerCase().includes("incorrect")) {
                    globalStates.alertBody.set("Incorrect email or password.");
                }
                else {
                    globalStates.alertBody.set("Failed to log in, please try again later.");
                }

                globalStates.alertType.set("error");
                globalStates.alertTitle.set("Error");
                globalStates.showAlert.set(true);

            });

        globalStates.alertBody.set("Successfully sent an OTP. Kindly check your phone");
        globalStates.alertType.set("success");
        globalStates.alertTitle.set("Success");
        globalStates.showAlert.set(true);
        setTimeout(() => {
            globalStates.showAlert.set(false);
        }, 2000);
    }

    return (
        <Grid container
            spacing={0}
            p={{ xs: 2, sm: 2 }}
        // style={{ backgroundColor: ConstantStyles['light-grey'], paddingTop: "0px", paddingBottom: "30px" }}
        >
            <Grid item xs={12} sm={12} pr={{ xs: 0, sm: 1 }}>
                <p style={{ fontSize: "16px", marginBottom: "5px" }}>{props.type} <span style={{ fontWeight: "bold", color: "red" }}>*</span></p>
                <CustomTextField
                    value={contact}
                    onChange={setContact}
                    placeholder={"Enter " + props.type}
                    type="text"
                    id="email"
                    disabled={isLoggingIn}
                    customSX={{
                        padding: "6px 15px",
                        marginBottom: "20px"
                    }}
                />
            </Grid>
            <Grid item xs={9} sm={10}>
                <p style={{ fontSize: "16px", marginBottom: "5px" }}>OTP <span style={{ fontWeight: "bold", color: "red" }}>*</span></p>
                <CustomTextField
                    value={otp}
                    onChange={setOtp}
                    placeholder={"Enter OTP"}
                    type="text"
                    id="email"
                    disabled={isLoggingIn}
                    customSX={{
                        padding: "6px 15px",
                        marginBottom: "20px"
                    }}
                />
            </Grid>
            <Grid item xs={1} sm={2}>
                <p style={{ fontSize: "16px", marginBottom: "5px" }}>&nbsp;</p>
                <CustomButton
                    buttonLabel={"Send"}
                    type="submit"
                    onClick={handleSendOTP}
                    disabled={isLoggingIn}
                    customSX={{
                        width: "100%",
                        padding: "6px 15px",
                        color: ConstantStyles.black,
                        border: ConstantStyles['default-border'],
                        backgroundColor: ConstantStyles.white,
                        verticalAlign: "top",
                        "&:hover": {
                            backgroundColor: ConstantStyles.primary,
                            color: ConstantStyles.white,
                            boxShadow: "none"
                        },
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={12}>
                <CustomButton
                    buttonLabel={"Submit"}
                    onClick={() => handleUpdate()}
                    type="submit"
                    disabled={contact === "" || otp === "" ? true : false}
                    customSX={{
                        width: "25%",
                        float: "right",
                        padding: "6px 15px",
                        color: ConstantStyles.white,
                        border: ConstantStyles['default-border'],
                        verticalAlign: "top",
                        "&:hover": {
                            backgroundColor: ConstantStyles.primary,
                            color: ConstantStyles.white,
                            boxShadow: "none"
                        },
                    }}
                />
            </Grid>
        </Grid >
    )


}


export default Phone;