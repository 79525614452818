import { useState, useEffect } from "react";
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from '../../../../utils/GlobalStates';
import ConstantStyles from "../../../../styles/ConstantStyles.json";

import {
    Grid,
    Card,
    CardContent,
    CardMedia
} from '@mui/material';

import bingostar from '../../../../assets/img/bingostar.png';
import happybingo from '../../../../assets/img/happybingo.png';
import samplebingoplus from '../../../../assets/img/sample-bingoplus.png';
import { LaunchLiveGame, GetPlayerGame, GetTopDaily , GetStreamToken} from "../../../../utils/Endpoints";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import CustomIFrame from "../../../shared/CustomIFrame";
const LiveSection = (props) => {
    const globalStates = useHookState(GlobalStates);
    const [topList, setTopList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true)
        GetDetails()
        setTimeout(() => {
            setIsLoading(false)
        }, 1000);
    }, []);

    const GetDetails = () => {
        GetTopDaily()
            .then(response => {
                let dailyResponse = response?.data?.data;
                setTopList(dailyResponse)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                // setIsTableLoading(false);
            });
    }


    const playerActiveGame = (type) => {
        GetPlayerGame(type)
            .then(response => {
                // console.log(response)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const LaunchThisGame = (game_id, type) => {
        playerActiveGame(type)
        LaunchLiveGame(game_id)
            .then(response => {
                if (localStorage.getItem("username")) {
                    window.location.href = response.data.data.url;
                }
                else {
                    window.location.href = "/login";
                }
            })
            .catch(error => {
                window.location.href = "/login";
                console.log(error)
            })
        setTimeout(() => {
            globalStates.showAlert.set(false);
        }, 2000);
    }

    return (
        <Grid container justifyContent="center" spacing={2} p={{ xs: 2, sm: 4 }} style={{ backgroundColor: ConstantStyles['light-grey'], paddingTop: "0px", paddingBottom: "30px" }} >

            <Grid item xs={12} sm={8}>

                <Grid container spacing={2} p={0}  >
                    <Grid item xs={12} sm={12}>

                        <Card
                            sx={{
                                maxWidth: "100%",
                                minHeight: { xs: "160px", sm: "160", md: "180px", lg: "280px" }
                            }}
                            style={{
                                backgroundColor: ConstantStyles.white,
                                color: ConstantStyles['dark-grey'],
                                border: "3px solid #00a6fc",
                                boxShadow: ConstantStyles['default-box-shadow2'], borderRadius: "12px"
                            }}>
                            <CardContent sx={{
                                padding: 0,
                                "&:last-child": {
                                    paddingBottom: 0
                                },
                                position:'relative',
                                minHeight: { xs: "160px", sm: "160", md: "180px", lg: "280px" }
                            }}>
                                {/* <div id="playerDiv" style={{ width: "100%" }}></div> */}
                                <CustomIFrame></CustomIFrame>
                                {/* <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Kxh2fbx8WfQ" title="A nice piano piece to listen to while smelling the spring scent" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
                                {/* <iframe width="100%" height="100%" src="https://www.youtube.com/watch?v=Kxh2fbx8WfQ" title="Megabingo live vid" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
                                {/* <img className='liveImage' height="320" style={{ width: "100%" }} src={samplebingoplus} /> */}
                            </CardContent>
                        </Card>
                    </Grid>

                    {isLoading ?

                        <SkeletonTheme baseColor="#d3d3d3" highlightColor="#444">
                            <p style={{
                                width: "95%",
                                textAlign: "center",
                                margin: "10px auto"
                            }}>

                                <Skeleton count={15} />
                            </p>
                        </SkeletonTheme> :
                        props?.gamesLive.map((item, index) => (
                            <Grid item xs={6} sm={6} key={index} sx={{
                                display: item.room_id === 90 ? "none" : ""
                            }} >

                                <Card
                                    sx={{ maxWidth: "100%" }}
                                    style={{
                                        backgroundColor: "transparent",
                                        color: ConstantStyles.white,
                                        cursor: "pointer",
                                        border: "unset",
                                        minHeight: "152.6px",
                                        boxShadow: "unset"
                                    }}

                                    onClick={() => {
                                        let game_id = item.game_id
                                        LaunchThisGame(game_id, "live-bingo")
                                        // window.location = `https://live.888bingo.ph/api/games/live-bingo/${item.game_id}`
                                        // window.open(`https://uat.888bingo.ph${item.href}`, `_blank`);
                                    }}

                                >
                                    {item.game_id === 14110953 || item.room_id === 89 ?
                                        <CardMedia
                                            component="img"
                                            image={happybingo}
                                            alt={item.room_name}
                                            sx={{
                                                objectFit: "unset",
                                                padding: "0%",
                                                border: "0px solid",
                                                color: "#222"
                                            }}
                                        /> : null
                                    }
                                    {item.game_id === 13874164 || item.room_id === 91 ?
                                        <CardMedia
                                            component="img"
                                            image={bingostar}
                                            alt={item.room_name}
                                            sx={{
                                                objectFit: "unset",
                                                padding: "0%",
                                                border: "0px solid",
                                                color: "#222"
                                            }}
                                        /> : null
                                    }
                                    {item.game_id === 13871504 ?
                                        <CardMedia
                                            component="img"
                                            image={"https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/330px-No-Image-Placeholder.svg.png?20200912122019"}
                                            alt={item.room_name}
                                            sx={{
                                                objectFit: "unset",
                                                padding: "0%",
                                                border: "0px solid",
                                                color: "#222",
                                                height: "170px"
                                            }}
                                        /> : null
                                    }
                                </Card>
                            </Grid>
                        ))}

                </Grid>
            </Grid>
            <Grid item xs={12} sm={4} >

                <Card sx={{ maxWidth: "100%" }} style={{
                    backgroundColor: ConstantStyles.white,
                    color: ConstantStyles['dark-grey'],
                    border: "3px solid #00a6fc",
                    minHeight: { xs: "auto", sm: "480px" },
                    boxShadow: ConstantStyles['default-box-shadow2'], borderRadius: "12px"
                }}>
                    <CardContent sx={{
                        padding: "0",
                        "&:last-child": {
                            paddingBottom: 0
                        }
                    }}>
                        <p style={{
                            color: "#1a1a1a",
                            fontSize: "16px",
                            textAlign: "center",
                            fontWeight: ConstantStyles['bold'],
                            borderBottom: "2px solid #00a6fc",
                            padding: "2%"
                        }}>CONGRATULATIONS TO OUR WINNERS!</p>
                        <Grid
                            className='winnersLists'
                            sx={{
                                overflow: "auto",
                                maxHeight: { xs: "auto", sm: "350px", md: "380px", lg: "580px" },
                                minHeight: { xs: "auto", sm: "350px", md: "380px", lg: "580px" },
                            }}
                        >
                            {topList?.map((text, index) => (
                                <Grid container
                                    spacing={2}
                                    p={1}
                                    pt={1}
                                    sx={{
                                        borderBottom: "2px solid #00a6fc"
                                    }}
                                    key={index}
                                >
                                    <Grid item xs={1} sm={2} >
                                        {index + 1}.
                                    </Grid>
                                    <Grid item xs={8} sm={6}>
                                        <p><b>{text.username}</b></p>
                                        <small style={{ textTransform: "capitalize" }}>{text.type}</small>
                                    </Grid>
                                    <Grid item xs={3} sm={4} style={{ textAlign: "center" }}>
                                        <p style={{
                                            backgroundColor: "#FFC107",
                                            color: ConstantStyles.white,
                                            borderRadius: "50px"
                                        }}>{(text.total_amount).toFixed(2)}</p>
                                    </Grid>
                                </Grid>
                            ))}


                        </Grid>
                    </CardContent>
                </Card>


            </Grid>

        </Grid >
    )


}


export default LiveSection;