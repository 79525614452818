import { useState, useEffect } from "react";
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from '../../../../utils/GlobalStates';
import ConstantStyles from "../../../../styles/ConstantStyles.json";
import SimpleImageSlider from "react-simple-image-slider";
import { LaunchLiveGame, GetPlayerGame, GetPromo } from "../../../../utils/Endpoints";

import {
    Grid
} from '@mui/material';


const images = [
    { url: "https://megabingo-client-files.s3.ap-southeast-1.amazonaws.com/megabingo-promotion/photo_2023-02-21_10-48-48.jpg-1676951935764.jpg", game_id: 14110953 },
    { url: "https://megabingo-client-files.s3.ap-southeast-1.amazonaws.com/megabingo-promotion/photo_2023-02-21_10-48-49.jpg-1676951488068.jpg", game_id: 13874164 }
];

function clean(obj) {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
        }
    }
    return obj
}

const Banners = (props) => {
    const globalStates = useHookState(GlobalStates);
    const [promoList, setPromoList] = useState([]);
    const [imageGame, setImageGame] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setTimeout(function () {
            // alert("sheeeesh")
            getPromoList();
            setImageGame(images)
            setLoading(!loading)
        }, 250);
    }, [promoList.length, imageGame.length]);

    const getPromoList = () => {
        GetPromo()
            .then(response => {
                let newList = (response?.data?.data)?.map((col, index) => {
                    if (col.main_img !== null) {
                        return {
                            index: index,
                            url: col.main_img ?? ""
                        }
                    }
                    else {
                        return null;
                    }
                });
                setPromoList(clean(newList))
                // console.log(clean(newList))
            })
            .catch(error => {
                console.log(error)
            })

    }

    const playerActiveGame = (type) => {
        GetPlayerGame(type)
            .then(response => {
                // console.log(response)
            })
            .catch(error => {
                console.log(error)
            })
    }


    const LaunchThisGame = (game_id, type) => {
        playerActiveGame(type)
        LaunchLiveGame(game_id)
            .then(response => {
                if (localStorage.getItem("username")) {
                    window.location.href = response.data.data.url;
                }
                else {
                    window.location.href = "/login";
                }
            })
            .catch(error => {
                window.location.href = "/login";
                console.log(error)
            })
        setTimeout(() => {
            globalStates.showAlert.set(false);
        }, 2000);
    }

    if (!loading) {

        return (

            <Grid container justifyContent="center" p={{ xs: 2, sm: 4 }} style={{ backgroundColor: ConstantStyles['light-grey'], paddingTop: "0px", paddingBottom: "30px" }} >
                <Grid item xs={12} sm={12} p={0}  >
                    <div className={globalStates.isMobile.get() ? "ImageSlider" : "ImageSliderDif"}>
                        <SimpleImageSlider
                            width={globalStates.isMobile.get() ? 400 : 1155}
                            height={globalStates.isMobile.get() ? 100 : 280}
                            images={promoList.length === 0 ? imageGame : promoList}
                            showBullets={false}
                            showNavs={true}
                            autoPlay={true}
                            loop={true}
                            style={{
                                cursor: "pointer"
                            }}
                            className="SliderContainer"
                            onClick={(idx, event) => {
                                if(promoList.length === 0){
                                    let game_id = images[idx].game_id
                                    LaunchThisGame(game_id, "live-bingo")
                                }
                                else{
                                    
                                }
                            }}
                        />
                    </div>
                </Grid>
            </Grid >

        )
    }
    else {
        return " "
    }

}


export default Banners;