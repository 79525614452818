import { useState, useEffect, useMemo } from "react";
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from '../../../../../utils/GlobalStates';
import ConstantStyles from "../../../../../styles/ConstantStyles.json";
import CustomTextField from "../../../../shared/CustomTextField";
import CustomButton from "../../../../shared/CustomButton";
import {
    Grid,
    Card,
    CardContent,
} from '@mui/material';
import { UpdateKYC } from "../../../../../utils/Endpoints";


const Password = (props) => {
    const globalStates = useHookState(GlobalStates);
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    useEffect(() => {
        setPassword(props?.player.password || "")
        setConfirmPassword(props?.player.password || "")
    }, []);

    const hanldeSubmit = () => {
        if ((password === confirmPassword) && password && confirmPassword) {

            let body = {
                "username": props?.player.username,
                "data": {
                    "password": password,
                    "confirm_password": confirmPassword,
                }
            }
            update(body)

        }
        else {
            globalStates.alertBody.set("Password blank / not matched");
            globalStates.alertType.set("error");
            globalStates.alertTitle.set("Error");
            globalStates.showAlert.set(true);
        }
        setTimeout(() => {
            globalStates.showAlert.set(false);
        }, 2000);
    }


    const update = (body) => {
        UpdateKYC(body)
            .then(response => {
                globalStates.alertBody.set("Password Successfully updated");
                globalStates.alertType.set("success");
                globalStates.alertTitle.set("Success");
                globalStates.showAlert.set(true);
            })
            .catch(error => {
                console.log(error)
                globalStates.alertType.set("error");
                globalStates.alertTitle.set("Error");
                globalStates.alertBody.set("Failed to retrieve player details.");
                globalStates.showAlert.set(true);

                setTimeout(() => {
                    globalStates.showAlert.set(false);
                }, 3000);
            })
            .finally(() => {
                // setIsTableLoading(false);
                props.func(Date.now());
            });
    }

    return (
        <Grid container
            spacing={0}
            p={{ xs: 2, sm: 2 }}
        >
            <Grid item xs={12} sm={12} pr={{ xs: 0, sm: 1 }}>
                <p style={{ fontSize: "16px", marginBottom: "5px" }}>Password <span style={{ fontWeight: "bold", color: "red" }}>*</span></p>
                <CustomTextField
                    value={password}
                    onChange={setPassword}
                    placeholder={"Password"}
                    type="Password"
                    id="email"
                    disabled={isLoggingIn}
                    customSX={{
                        padding: "6px 15px",
                        marginBottom: "20px"
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <p style={{ fontSize: "16px", marginBottom: "5px" }}>Confirm Password <span style={{ fontWeight: "bold", color: "red" }}>*</span></p>
                <CustomTextField
                    value={confirmPassword}
                    onChange={setConfirmPassword}
                    placeholder={"Confirm Password"}
                    type="Password"
                    id="email"
                    disabled={isLoggingIn}
                    customSX={{
                        padding: "6px 15px",
                        marginBottom: "20px"
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={12}>
                <CustomButton
                    buttonLabel={"Submit"}
                    onClick={() => hanldeSubmit()}
                    type="submit"
                    disabled={isLoggingIn}
                    customSX={{
                        width: "25%",
                        float: "right",
                        padding: "6px 20px",
                        color: ConstantStyles.white,
                        border: ConstantStyles['default-border'],
                        verticalAlign: "top",
                        "&:hover": {
                            backgroundColor: ConstantStyles.primary,
                            color: ConstantStyles.white,
                            boxShadow: "none"
                        },
                    }}
                />
            </Grid>
        </Grid >
    )


}


export default Password;