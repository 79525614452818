import { useState, useEffect } from "react";
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from '../../../../utils/GlobalStates';
import ConstantStyles from "../../../../styles/ConstantStyles.json";
import CloseIcon from '@mui/icons-material/Close';
import moment from "moment";
import {
    Button,
    Grid,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';

const CardsSection = (props) => {
    const globalStates = useHookState(GlobalStates);
    const [activeTab, setActiveTab] = useState(false);
    const [news, setNews] = useState(props.newsList || []);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState([]);
    const [newsLength, setNewsLength] = useState(0);
    let url = window.location.pathname;
    const handleClose = () => {
        setOpen(false);
    };
    // console.log(props.newsList)
    useEffect(() => {
        setNews(props.newsList)

        let contentLength = props?.newsList?.length || 0;
        setNewsLength(contentLength)
        // for (let i = 0; i < contentLength; i++) {
        //     console.log(props.newsList[i].content)
        //     document.getElementById("contents" + i).innerHTML = props?.newsList[i]?.content;
        // }
    }, [props, activeTab]);

    const clickCard = (text) => {
        setActiveTab(!activeTab)
        setOpen(true);
        setSelected(text)
        setTimeout(() => {
            document.getElementById("contentsSelected").innerHTML = text?.content;
        }, "250")
    }

    return (
        <>
            <Grid container justifyContent="center" spacing={2} p={{ xs: 2, sm: 4 }} style={{ backgroundColor: ConstantStyles['light-grey'], paddingTop: "0px", paddingBottom: "10px" }} >
                {props.newsList?.map((text, index) => (
                    index < 2 && url !== "/news" ?
                        <Grid item xs={12} sm={12}
                            onClick={() => clickCard(text)} >
                            <Card sx={{ maxWidth: "100%" }} style={{
                                backgroundColor: ConstantStyles.white,
                                color: ConstantStyles['dark-grey'],
                                boxShadow: "unset", boxShadow: ConstantStyles['default-box-shadow2'], borderRadius: "12px"
                            }}>
                                <CardContent sx={{
                                    padding: "1%",
                                    paddingLeft: "4%",
                                    paddingRight: "4%",
                                    borderRadius: "12px",
                                    "&:last-child": {
                                        paddingBottom: 0
                                    }
                                }}>
                                    <Grid container spacing={0} >
                                        <Grid item xs={12} sm={10} pb={1}>
                                            <p
                                                style={{
                                                    fontSize: "20px",
                                                    fontWeight: "bold"
                                                }}
                                            > <img style={{ pointerEvents: "none", marginRight: "5px", marginLeft: "5px", }} width="24" src={process.env.PUBLIC_URL + '/home/article-title-icon.svg'} />&nbsp; {text.title}</p>
                                            <p
                                                style={{
                                                    fontSize: "14px",
                                                    padding: "1% 0%",
                                                    fontWeight: "normal"
                                                }}
                                            >
                                                <img style={{ pointerEvents: "none", marginRight: "5px", marginLeft: "5px", }} width="24" src={process.env.PUBLIC_URL + '/home/user-icon.svg'} />&nbsp;
                                                <span className="antonioFont" style={{ color: "#6c6c6c" }}>{text.posted_by}</span>
                                                <span
                                                    className="antonioFont"
                                                    style={{
                                                        marginLeft: "10px", border: "2px solid #6c6c6c",
                                                        color: "#6c6c6c", padding: "5px", borderRadius: "50px"
                                                    }}>{text.hashtag || "#"}
                                                </span>
                                            </p>
                                            <p
                                                className="antonioFont"
                                                style={{
                                                    fontSize: "16px",
                                                    padding: "1% 1%",
                                                    fontWeight: "normal",
                                                    color: "#6c6c6c"
                                                }}
                                            >
                                                {text.description}
                                            </p>
                                        </Grid>
                                        <Grid item xs={12} sm={2} pb={1}>
                                            <img style={{ pointerEvents: "none", textAlign: "center", margin: "0 auto", display: "block", width: "75%" }} src={text.feature_img} /><br />
                                        </Grid>

                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        :
                        null
                ))}


                {props.newsList?.map((text, index) => (
                    url === "/news" ?
                        <Grid item xs={12} sm={12}
                            onClick={() => clickCard(text)} >
                            <Card sx={{ maxWidth: "100%" }} style={{
                                backgroundColor: ConstantStyles.white,
                                color: ConstantStyles['dark-grey'],
                                boxShadow: "unset", boxShadow: ConstantStyles['default-box-shadow2'], borderRadius: "12px"
                            }}>
                                <CardContent sx={{
                                    padding: "1%",
                                    paddingLeft: "4%",
                                    paddingRight: "4%",
                                    borderRadius: "12px",
                                    "&:last-child": {
                                        paddingBottom: 0
                                    }
                                }}>
                                    <Grid container spacing={0} >
                                        <Grid item xs={12} sm={10} pb={1}>
                                            <p
                                                style={{
                                                    fontSize: "20px",
                                                    fontWeight: "bold"
                                                }}
                                            > <img style={{ pointerEvents: "none", marginRight: "5px", marginLeft: "5px", }} width="24" src={process.env.PUBLIC_URL + '/home/article-title-icon.svg'} />&nbsp; {text.title}</p>
                                            <p
                                                style={{
                                                    fontSize: "14px",
                                                    padding: "1% 0%",
                                                    fontWeight: "normal"
                                                }}
                                            >
                                                <img style={{ pointerEvents: "none", marginRight: "5px", marginLeft: "5px", }} width="24" src={process.env.PUBLIC_URL + '/home/user-icon.svg'} />&nbsp;
                                                <span className="antonioFont" style={{ color: "#6c6c6c" }}>{text.posted_by}</span>
                                                <span
                                                    className="antonioFont"
                                                    style={{
                                                        marginLeft: "10px", border: "2px solid #6c6c6c",
                                                        color: "#6c6c6c", padding: "5px", borderRadius: "50px"
                                                    }}>{text.hashtag || "#"}
                                                </span>
                                            </p>
                                            <p
                                                className="antonioFont"
                                                style={{
                                                    fontSize: "16px",
                                                    padding: "1% 1%",
                                                    fontWeight: "normal",
                                                    color: "#6c6c6c"
                                                }}
                                            >
                                                {text.description}
                                            </p>
                                        </Grid>
                                        <Grid item xs={12} sm={2} pb={1}>
                                            <img style={{ pointerEvents: "none", textAlign: "center", margin: "0 auto", display: "block", width: "75%" }} src={text.feature_img} /><br />
                                        </Grid>

                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        : null
                ))}

                {(newsLength > 2 && url !== "/news") && url !== "/promo" ?
                    <>
                        <a href="/news" style={{ color: "#f8b12c", fontWeight: "bold", textDecoration: "none", marginTop: "25px", fontSize: "24px", border: "1px solid", padding: "1% 4%", borderRadius: "10px" }}>See More</a>
                    </>
                    :
                    null
                }
            </Grid >
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={"xl"}
                PaperProps={{
                    sx: {
                        top: "10px",
                        position: "absolute",
                        minWidth: "850px",
                        maxWidth: "650px"
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" >

                    <span onClick={handleClose} style={{ textAlign: "right", position: "absolute", right: "15px", top: "5px" }} >
                        <CloseIcon sx={{ cursor: "pointer" }} />
                    </span>
                    <p
                        style={{
                            fontSize: "20px",
                            fontWeight: "bold"
                        }}
                    > <img style={{ pointerEvents: "none", marginRight: "5px", marginLeft: "5px", }} width="24" src={process.env.PUBLIC_URL + '/home/article-title-icon.svg'} />&nbsp; {selected?.title}

                        <p
                            style={{
                                fontSize: "14px",
                                padding: "1% 0%",
                                fontWeight: "normal"
                            }}
                        >
                            <img style={{ pointerEvents: "none", marginRight: "5px", marginLeft: "5px", }} width="24" src={process.env.PUBLIC_URL + '/home/user-icon.svg'} />&nbsp;
                            <span className="" style={{ color: "#222", marginRight: "10px" }}>{selected?.posted_by}</span>
                            <small style={{ color: "gray" }}> {moment(selected?.created_date).format('MMMM DD, YYYY') || " "}</small>
                        </p>
                    </p>
                </DialogTitle>
                {/*  */}
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Grid item xs={12} sm={12} >
                            <Grid container spacing={0} >
                                <Grid item xs={12} sm={12} pb={1}>
                                    <img style={{ pointerEvents: "none", textAlign: "center", margin: "0 auto", display: "block", width: "70%" }} src={selected?.banner_img} /><br />
                                </Grid>
                                <Grid item xs={12} sm={12} pb={1}>
                                    <p
                                        className="antonioFont"
                                        style={{
                                            fontSize: "16px",
                                            padding: "1% 1%",
                                            fontWeight: "normal",
                                            color: "#6c6c6c"
                                        }}
                                    >
                                        {selected?.description}
                                    </p>
                                    <div id={`contentsSelected`}>
                                        {/* {text.content} */}
                                    </div>
                                </Grid>

                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
        </>
    )


}


export default CardsSection;