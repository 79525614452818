import { useState, useEffect } from "react";
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from '../../../../../utils/GlobalStates';
import ConstantStyles from "../../../../../styles/ConstantStyles.json";
import CustomTextField from "../../../../shared/CustomTextField";
import CustomButton from "../../../../shared/CustomButton";
import {
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { GetWallet, ImageUpload, Withdrawal, DepositAmount, GetGcashWallet } from "../../../../../utils/Endpoints";

const Wallet = (props) => {
    const globalStates = useHookState(GlobalStates);
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [amount, setAmount] = useState(0);
    const [type, setType] = useState("");
    const [open, setOpen] = useState(false);
    const [bank, setBank] = useState("Gcash");
    const [accountNumber, setAccountNumber] = useState("");
    const [accountName, setAccountName] = useState("");
    const [remarks, setRemarks] = useState("");
    const [receipt, setReceipt] = useState(null);
    const [mainBalance, setMainBalance] = useState(0);
    const [frameValue, setFrameValue] = useState("");
    const [gcashOpen, setGcashOpen] = useState(false);
    const [isLoading, setIsloading] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
        setAccountNumber("")
        setAccountName("")
        setAmount(0)
        setRemarks("")
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setMainBalance("fetching...")
        GetBalance()
    }, [isLoading]);

    const GetBalance = () => {
        GetGcashWallet()
            .then(response => {
                if (response.data.status === 1) {
                    setTimeout(() => {
                        GetWallet()
                            .then(response => {
                                setMainBalance(response.data.data?.main_balance ?? 0)
                            })
                            .catch(error => {
                                console.log(error)
                                // globalStates.alertType.set("error");
                                // globalStates.alertTitle.set("Error");
                                // globalStates.alertBody.set("Failed to retrieve wallet.");
                                // globalStates.showAlert.set(true);

                                setTimeout(() => {
                                    globalStates.showAlert.set(false);
                                }, 2000);
                            })

                    }, "1500")
                }
            })
            .catch(error => {
                console.log(error)
                // globalStates.alertType.set("error");
                // globalStates.alertTitle.set("Error");
                // globalStates.alertBody.set("Failed to retrieve wallet.");
                // globalStates.showAlert.set(true);

                setTimeout(() => {
                    globalStates.showAlert.set(false);
                }, 2000);
            })
    }

    const refreshBalance = () => {
        GetBalance()
    }

    const uploadImg = () => {
        let formData = new FormData();
        if ((parseFloat((parseFloat(amount)).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]) >= 0 && parseFloat((parseFloat(amount)).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]) >  49.99)) {
            if (accountNumber !== "" && accountName !== "") {
                formData.append(receipt?.[0], formData);
                if (parseFloat(mainBalance) > parseFloat((parseFloat(amount)).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]) && parseFloat((parseFloat(amount)).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]) > 49.99) {
                    ImageUpload('withdraw_receipt', receipt?.[0])
                        .then(response => {
                            let body = {
                                settlement_id: bank,
                                settlement_account_number: accountNumber,
                                settlement_account_name: accountName,
                                amount: parseFloat((parseFloat(amount)).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]),
                                receipt_file: response.data.date.withdraw_receipt ?? "-",
                                remarks: remarks

                            }
                            handleWithdraw(body)
                        })
                        .catch(error => {
                            // console.log(error);
                            setIsLoggingIn(false);
                            globalStates.alertType.set("error");
                            globalStates.alertTitle.set("Error");
                            globalStates.showAlert.set(true);

                        });
                }
                else if (parseFloat((parseFloat(amount)).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]) <  49.99) {
                    globalStates.alertBody.set("Amount should be 50.00 and above");
                    globalStates.alertType.set("error");
                    globalStates.alertTitle.set("Error");
                    globalStates.showAlert.set(true);
                }
                else {
                    globalStates.alertBody.set("Insufficient amount. Your current balance: " + mainBalance);
                    globalStates.alertType.set("error");
                    globalStates.alertTitle.set("Error");
                    globalStates.showAlert.set(true);
                }


            }
            else {
                globalStates.alertBody.set("Please fillup all required fields '*'");
                globalStates.alertType.set("error");
                globalStates.alertTitle.set("Error");
                globalStates.showAlert.set(true);
            }
        }
        else {

            if (parseFloat((parseFloat(amount)).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]) > parseFloat(mainBalance)) {
                globalStates.alertBody.set("Insufficient amount current balance: " + mainBalance);
                globalStates.alertType.set("error");
                globalStates.alertTitle.set("Error");
                globalStates.showAlert.set(true);
            }
            else {
                globalStates.alertBody.set("Amount should be 50.00 and above");
                globalStates.alertType.set("error");
                globalStates.alertTitle.set("Error");
                globalStates.showAlert.set(true);
            }

        }

        setTimeout(() => {
            globalStates.showAlert.set(false);

        }, 2000);
    }

    const handleWithdraw = (body) => {
        setIsloading(true)
        Withdrawal(body)
            .then(response => {
                globalStates.alertBody.set("Withdrawal request with amount of " + response.data.data.amount + " already sent");
                globalStates.alertType.set("success");
                globalStates.alertTitle.set("Success");
                globalStates.showAlert.set(true);
                props.func(Date.now());
                setOpen(false);
                setAccountNumber("")
                setAccountName("")
                setRemarks("")
                setAmount(0)
                setTimeout(() => {
                    setIsloading(false)
                }, "1500")
            })
            .catch(error => {
                setIsLoggingIn(false);
                globalStates.alertType.set("error");
                globalStates.alertTitle.set("Error");
                globalStates.showAlert.set(true);

            });
    }

    const handleDeposit = () => {
        setIsloading(true)
        DepositAmount(amount)
            .then(response => {
                if (response?.data.status === 0) {
                    globalStates.alertType.set("error");
                    globalStates.alertTitle.set("Error");
                    globalStates.alertBody.set(response?.data.message);
                    globalStates.showAlert.set(true);
                    setIsloading(false)
                }
                else {
                    setFrameValue(response?.data.data.checkout_url)
                    setOpen(false)
                    setGcashOpen(true)
                    setIsloading(false)
                    // window.location.href = response?.data.data.checkout_url;
                }
            })
            .catch(error => {
                setIsLoggingIn(false);
                globalStates.alertType.set("error");
                globalStates.alertTitle.set("Error");
                globalStates.alertBody.set(error?.message);
                globalStates.showAlert.set(true);

            });
        setTimeout(() => {
            globalStates.showAlert.set(false);
        }, 2000);
    }

    return (
        <>
            <Grid container
                spacing={1}
                justifyContent="center"
                p={{ xs: 2, sm: 2 }}
            >
                <Grid item xs={12} sm={8}>
                    <h3>My Wallet
                    </h3>
                    <span>
                        <h2>{mainBalance ?? "0.00"}
                            <small onClick={refreshBalance} style={{ fontSize: "12px", cursor: "pointer" }}> <AutorenewIcon style={{ pointerEvents: "none", verticalAlign: "unset", cursor: "pointer" }} /></small></h2>
                    </span>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <CustomButton
                        buttonLabel={"Deposit"}
                        onClick={() => {
                            setType("Deposit")
                            handleClickOpen()
                        }}
                        type="submit"
                        disabled={isLoggingIn}
                        customSX={{
                            width: "100%",
                            float: "right",
                            padding: "11px 20px",
                            color: ConstantStyles.white,
                            border: ConstantStyles['default-border'],
                            verticalAlign: "top",
                            "&:hover": {
                                backgroundColor: ConstantStyles.primary,
                                color: ConstantStyles.white,
                                boxShadow: "none"
                            },
                        }}
                    />
                    <CustomButton
                        buttonLabel={"Withdraw"}
                        type="submit"
                        onClick={() => {
                            setType("Withdraw")
                            handleClickOpen()
                        }}
                        disabled={isLoggingIn}
                        customSX={{
                            width: "100%",
                            padding: "11px 20px",
                            marginTop: "5%",
                            color: ConstantStyles.black,
                            border: ConstantStyles['default-border'],
                            backgroundColor: ConstantStyles.white,
                            verticalAlign: "top",
                            "&:hover": {
                                backgroundColor: ConstantStyles.primary,
                                color: ConstantStyles.white,
                                boxShadow: "none"
                            },
                        }}
                    />
                </Grid>

            </Grid>
            <Dialog
                sx={{
                    "& .MuiDialog-container": {
                        alignItems: "flex-start"
                    },
                }}
                PaperProps={{ sx: { mt: "50px", width: "100%" } }}
                open={open}
                // onClose={handleClose}
                disableEscapeKeyDown
                disableBackdropClick
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {type}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {type === "Withdraw" ?
                            <>
                                <p style={{ fontSize: "16px", marginBottom: "5px" }}>Bank <span style={{ fontWeight: "bold", color: "red" }}>*</span></p>
                                <CustomTextField
                                    value={bank}
                                    onChange={setBank}
                                    placeholder={"Gcash"}
                                    type="text"
                                    id="email"
                                    disabled={true}
                                    customSX={{
                                        padding: "6px 15px",
                                        marginBottom: "10px"
                                    }}
                                />

                                <p style={{ fontSize: "16px", marginBottom: "5px" }}>Account Number <span style={{ fontWeight: "bold", color: "red" }}>*</span></p>
                                <CustomTextField
                                    value={accountNumber}
                                    onChange={setAccountNumber}
                                    placeholder={"Enter Account Number"}
                                    type="number"
                                    id="email"
                                    disabled={isLoggingIn}
                                    customSX={{
                                        padding: "6px 15px",
                                        marginBottom: "10px"
                                    }}
                                />

                                <p style={{ fontSize: "16px", marginBottom: "5px" }}>Account Name <span style={{ fontWeight: "bold", color: "red" }}>*</span></p>
                                <CustomTextField
                                    value={accountName}
                                    onChange={setAccountName}
                                    placeholder={"Enter Account Name"}
                                    type="text"
                                    id="email"
                                    disabled={isLoggingIn}
                                    customSX={{
                                        padding: "6px 15px",
                                        marginBottom: "10px"
                                    }}
                                />

                            </>
                            :
                            null
                        }
                        <p style={{ fontSize: "16px", marginBottom: "5px" }}>Amount <span style={{ fontWeight: "bold", color: "red" }}>*</span></p>
                        <CustomTextField
                            value={amount}
                            onChange={setAmount}
                            placeholder={"Enter Amount"}

                            type="number"
                            id="email"
                            disabled={isLoading}
                            customSX={{
                                padding: "6px 15px",
                                marginBottom: "10px"
                            }}
                        />
                        {type === "Withdraw" ?
                            <>
                                <p style={{ fontSize: "16px", marginBottom: "5px" }}>Remarks</p>
                                <CustomTextField
                                    value={remarks}
                                    onChange={setRemarks}
                                    placeholder={"Enter Remarks"}
                                    type="text"
                                    id="email"
                                    disabled={isLoggingIn}
                                    customSX={{
                                        padding: "6px 15px",
                                        marginBottom: "10px"
                                    }}
                                />
                            </>
                            : null}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <CustomButton
                        buttonLabel={"Cancel"}
                        type="submit"
                        onClick={() => {
                            handleClose()
                        }}
                        disabled={isLoggingIn}
                        customSX={{
                            width: "auto",
                            padding: "11px",
                            color: ConstantStyles.black,
                            border: ConstantStyles['default-border'],
                            backgroundColor: ConstantStyles.white,
                            verticalAlign: "top",
                            "&:hover": {
                                backgroundColor: ConstantStyles.primary,
                                color: ConstantStyles.white,
                                boxShadow: "none"
                            },
                        }}
                    />
                    {type === "Withdraw" ?
                        <CustomButton
                            buttonLabel={"Submit"}
                            onClick={() => {
                                uploadImg()
                            }}
                            type="submit"
                            disabled={isLoggingIn}
                            customSX={{
                                width: "auto",
                                padding: "11px",
                                color: ConstantStyles.white,
                                border: ConstantStyles['default-border'],
                                verticalAlign: "top",
                                "&:hover": {
                                    backgroundColor: ConstantStyles.primary,
                                    color: ConstantStyles.white,
                                    boxShadow: "none"
                                },
                            }}
                        /> :
                        <CustomButton
                            buttonLabel={"Submit"}
                            onClick={() => {
                                handleDeposit()
                            }}
                            type="submit"
                            disabled={isLoggingIn}
                            customSX={{
                                width: "auto",
                                padding: "11px",
                                color: ConstantStyles.white,
                                border: ConstantStyles['default-border'],
                                verticalAlign: "top",
                                "&:hover": {
                                    backgroundColor: ConstantStyles.primary,
                                    color: ConstantStyles.white,
                                    boxShadow: "none"
                                },
                            }}
                        />}
                </DialogActions>
            </Dialog>

            <Dialog
                sx={{
                    "& .MuiDialog-container": {
                        alignItems: "flex-start"
                    },
                }}
                PaperProps={{ sx: { mt: "50px", width: "100%" } }}
                open={gcashOpen}
                // onClose={handleClose}
                maxWidth='lg'
                maxHeight='lg'
                disableEscapeKeyDown
                disableBackdropClick
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <p style={{ float: "left", fontSize: "22px" }}>Deposit Via Gcash</p>
                    <CustomButton
                        buttonLabel={"Close"}
                        type="submit"
                        onClick={() => {
                            setGcashOpen(false)
                        }}
                        disabled={isLoggingIn}
                        customSX={{
                            width: "auto",
                            padding: "11px",
                            float: "right",
                            color: ConstantStyles.black,
                            border: ConstantStyles['default-border'],
                            backgroundColor: ConstantStyles.white,
                            verticalAlign: "top",
                            "&:hover": {
                                backgroundColor: ConstantStyles.primary,
                                color: ConstantStyles.white,
                                boxShadow: "none"
                            },
                        }}
                    />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <iframe src={frameValue} style={{ minHeight: "80vh" }} title="gcash payment" width="100%" height="100%"></iframe>
                    </DialogContentText>
                </DialogContent>
                {/* <DialogActions>
                    <CustomButton
                        buttonLabel={"Cancel"}
                        type="submit"
                        onClick={() => {
                            handleClose()
                        }}
                        disabled={isLoggingIn}
                        customSX={{
                            width: "auto",
                            padding: "11px",
                            color: ConstantStyles.black,
                            border: ConstantStyles['default-border'],
                            backgroundColor: ConstantStyles.white,
                            verticalAlign: "top",
                            "&:hover": {
                                backgroundColor: ConstantStyles.primary,
                                color: ConstantStyles.white,
                                boxShadow: "none"
                            },
                        }}
                    />
                    {type === "Withdraw" ?
                        <CustomButton
                            buttonLabel={"Submit"}
                            onClick={() => {
                                uploadImg()
                            }}
                            type="submit"
                            disabled={isLoggingIn}
                            customSX={{
                                width: "auto",
                                padding: "11px",
                                color: ConstantStyles.white,
                                border: ConstantStyles['default-border'],
                                verticalAlign: "top",
                                "&:hover": {
                                    backgroundColor: ConstantStyles.primary,
                                    color: ConstantStyles.white,
                                    boxShadow: "none"
                                },
                            }}
                        /> :
                        <CustomButton
                            buttonLabel={"Submit"}
                            onClick={() => {
                                handleDeposit()
                            }}
                            type="submit"
                            disabled={isLoggingIn}
                            customSX={{
                                width: "auto",
                                padding: "11px",
                                color: ConstantStyles.white,
                                border: ConstantStyles['default-border'],
                                verticalAlign: "top",
                                "&:hover": {
                                    backgroundColor: ConstantStyles.primary,
                                    color: ConstantStyles.white,
                                    boxShadow: "none"
                                },
                            }}
                        />}
                </DialogActions> */}
            </Dialog>
        </>
    )


}


export default Wallet;