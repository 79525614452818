import { useState, useEffect } from "react";
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from '../../../../utils/GlobalStates';
import ConstantStyles from "../../../../styles/ConstantStyles.json";
import { GetAnnouncement } from "../../../../utils/Endpoints";

import {
    Grid,
    Card,
    Box,
} from '@mui/material';

const Announcement = () => {
    const globalStates = useHookState(GlobalStates);
    const [announcement, setAnnouncement] = useState("");

    useEffect(() => {
        GetDetails()
    }, []);

    const GetDetails = () => {
        GetAnnouncement()
            .then(response => {
                // console.log(response?.data?.data)
                let announcement = response?.data?.data;
                let concatData = "";
                for (let i = 0; i < announcement.length; i++) {
                    concatData += `<span>${announcement[i].announcement} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>`;
                }
                document.getElementById("announcementValue").innerHTML = concatData
                setAnnouncement(concatData)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                // setIsTableLoading(false);
            });
    }
    return (
        <Grid container justifyContent="center" spacing={2} p={{ xs: 0, sm: 3 }} pb={{ xs: 0, sm: 1 }} style={{ backgroundColor: ConstantStyles['light-grey'], paddingTop: "0px" }} >

            <Grid item xs={12} sm={12} p={0} style={{ paddingTop: "0px" }}>

                <Card
                    sx={{
                        maxWidth: "100%", padding: "0px", position: "relative"
                    }}
                    style={{
                        backgroundColor: "transparent",
                        boxShadow: "unset",
                        padding: "0",
                        paddingLeft: "5px",
                        fontSize: "18px",
                        borderRadius: "10px",
                        color: "#1a1a1a"
                    }}>
                    <img src={process.env.PUBLIC_URL + '/home/announcement/announcement-board.svg'} />
                    <Box sx={{
                        position: "absolute",
                        top: { xs: "10%", sm: "25%", md: "25%", lg: "35%", xl: "35%" },
                        left: "3%",
                        width: { xs: "96%", sm: "96%" }
                    }} >
                        {/* <CampaignIcon /> */}
                        <img width={20} className="announcementIcon" style={{ paddingRight: "3px" }} src={process.env.PUBLIC_URL + '/home/announcement/sound-on-icon.svg'} />
                        <marquee className="announcement" style={{ width: "97.5%", float: "right", marginTop: "2px", fontSize: "16px" }}><span id="announcementValue"></span></marquee>
                    </Box>
                </Card>
            </Grid>
        </Grid>
    )


}


export default Announcement;