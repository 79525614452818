import { useState, useEffect, useMemo } from "react";
import Cookies from 'js-cookie';
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from '../../../utils/GlobalStates';
import ConstantStyles from "../../../styles/ConstantStyles.json";
import LiveSection from "./Section/LiveSection";
import Announcement from "./Section/Announcement";
import Gamelist from "./Section/Gamelist";
import Winners from "./Section/Winners";
import Banners from "./Section/Banners";
import CardsSection from "./Section/CardsSection";
import Box from '@mui/material/Box';

import { GetLiveGames, GetBingoGames, GetCSRF, GetActivePlayers, GetPlayerGame, GetNews } from "../../../utils/Endpoints";

const Homepage = ({ socket }) => {
    const globalStates = useHookState(GlobalStates);

    const [gamesClassic, setGamesClassic] = useState([]);
    const [gamesVariant, setGamesVariant] = useState([]);
    const [gamesLive, setGamesLive] = useState([]);
    const [playerCount, setPlayerCount] = useState(null);
    const [newsList, setNewsList] = useState(null);

    useEffect(() => {
        getGameList();
        getCRF();
        playerActiveGame();
        getNewsList();
    }, []);

    useEffect(() => {
        socket.on("message", function (e) {
            if (e.type === "active_player_count") {
                if (e.data !== "undefined") {
                    setPlayerCount(e.data)
                }
            }
        })
    }, [socket])

    const getGameList = () => {
        GetLiveGames()
            .then(response => {
                let live = response.data.data.rooms || [];
                const arr = Object.assign([], live);
                let live_game = arr?.map((col, index) => {
                    return {
                        game_id: col.game_id ?? "",
                        room_id: col.room_id ?? "",
                        room_name: col.room_name ?? "",
                        room_open: col.room_open ?? "",
                        room_uuid: col.room_uuid ?? "",
                        game_ticket_price: col.game_ticket_price ?? "",
                        game_style: col.game_style ?? "",
                        promotions: col.promotions ?? "",
                        game_prizes_name: col.game_prizes.name ?? "",
                        game_prizes_value: col.game_prizes.value ?? "",
                        // action: <Link to={`/subscribers/view-subscriber/${sub.subscriberID ?? ""}`} style={{ textDecoration: "none" }}>
                        //     <Tooltip title="View" placement="right" arrow><IconButton><Visibility color={"primary"} fontSize={"small"} /></IconButton></Tooltip>
                        // </Link>,
                    }
                });
                // console.log(live_game)
                setGamesLive(live_game);
            })
            .catch(error => {
                console.log(error)
                globalStates.alertType.set("error");
                globalStates.alertTitle.set("Error");
                globalStates.alertBody.set("Failed to retrieve game list.");
                globalStates.showAlert.set(true);

                setTimeout(() => {
                    globalStates.showAlert.set(false);
                }, 3000);
            })
            .finally(() => {
                // setIsTableLoading(false);
            });

        GetBingoGames()
            .then(response => {
                let classics = response.data.data.classic || [];
                let variants = response.data.data.variant || [];
                let games_classic = classics.map(col => {
                    return {
                        enabled: col.enabled ?? "",
                        status_demo: col.status.demo ?? "",
                        status_lobby: col.status.lobby ?? "",
                        status_test: col.status.test ?? "",
                        title: col.title ?? "",
                        name: col.name ?? "",
                        href: col.href.demo ?? "",
                        bg: col.icon.bg ?? "",
                        logo: col.icon.logo ?? "",
                        og: col.icon.og ?? "",
                        // action: <Link to={`/subscribers/view-subscriber/${sub.subscriberID ?? ""}`} style={{ textDecoration: "none" }}>
                        //     <Tooltip title="View" placement="right" arrow><IconButton><Visibility color={"primary"} fontSize={"small"} /></IconButton></Tooltip>
                        // </Link>,
                    }
                });

                let games_variants = variants.map(col => {
                    return {
                        enabled: col.enabled ?? "",
                        status_demo: col.status.demo ?? "",
                        status_lobby: col.status.lobby ?? "",
                        status_test: col.status.test ?? "",
                        title: col.title ?? "",
                        name: col.name ?? "",
                        href: col.href.demo ?? "",
                        bg: col.icon.bg ?? "",
                        logo: col.icon.logo ?? "",
                        og: col.icon.og ?? "",
                        // action: <Link to={`/subscribers/view-subscriber/${sub.subscriberID ?? ""}`} style={{ textDecoration: "none" }}>
                        //     <Tooltip title="View" placement="right" arrow><IconButton><Visibility color={"primary"} fontSize={"small"} /></IconButton></Tooltip>
                        // </Link>,
                    }
                });

                setGamesClassic(games_classic);
                setGamesVariant(games_variants);
            })
            .catch(error => {
                console.log(error)
                globalStates.alertType.set("error");
                globalStates.alertTitle.set("Error");
                globalStates.alertBody.set("Failed to retrieve game list.");
                globalStates.showAlert.set(true);

                setTimeout(() => {
                    globalStates.showAlert.set(false);
                }, 3000);
            })
            .finally(() => {
                // setIsTableLoading(false);
            });
    }

    const playerActiveGame = () => {
        if (localStorage.getItem("username")) {
            GetPlayerGame("lobby")
                .then(response => {
                    // console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })
        }

    }

    const getNewsList = () => {
        GetNews()
            .then(response => {
                // console.log(response?.data?.data)
                setNewsList(response?.data?.data)
            })
            .catch(error => {
                console.log(error)
            })


    }


    const getCRF = () => {
        GetCSRF()
            .then(response => {
                // console.log(response?.data?.data)
                Cookies.set('mb-csrf', response?.data?.data)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                // setIsTableLoading(false);
            });
        GetActivePlayers()
            .then(response => {
                setPlayerCount(response?.data?.data?.data)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                // setIsTableLoading(false);
            });

    }

    return (
        <div style={{ backgroundColor: ConstantStyles['light-grey'] }}>
            <Box sx={{
                backgroundColor: ConstantStyles['light-grey'],
                margin: "0 auto",
                maxWidth: "1440px",
                paddingLeft: { xs: "0px", sm: "0px", md: "220px" },
                minHeight: "850px",
                paddingTop: "20px",
                paddingBottom: "140px"
            }} >
                <Announcement />
                <LiveSection
                    playerCount={playerCount}
                    gamesClassic={gamesClassic}
                    gamesVariant={gamesVariant}
                    gamesLive={gamesLive}
                />

                <Gamelist
                    playerCount={playerCount}
                    gamesClassic={gamesClassic}
                    gamesVariant={gamesVariant}
                    gamesLive={gamesLive}
                />
                {/* <Winners /> */}
                <Banners gamesLive={gamesLive} newsList={newsList} />
                <CardsSection newsList={newsList} listLength={2} />
                {/* {localStorage.getItem("username") ?
                    <iframe src={`https://chat-feeder.pages.dev/?user_id=${localStorage.getItem("user_id")}&username=${localStorage.getItem("username")}`} width={1000} height={1000}>

                    </iframe>
                    : 
                    <iframe src={`https://chat-feeder.pages.dev/?user_id=7&username=BRCAL035848`} width={1000} height={1000}>

                    </iframe>} */}
            </Box >
            
        </div>
    )


}


export default Homepage;