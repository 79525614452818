import { useState, useEffect, useMemo } from "react";
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from '../../../utils/GlobalStates';
import ConstantStyles from "../../../styles/ConstantStyles.json";
import LiveSection from "./Section/LiveSection";
import Announcement from "./Section/Announcement";
import Gamelist from "./Section/Gamelist";
import Winners from "./Section/Winners";
import CardsSection from "./Section/CardsSection";
import Box from '@mui/material/Box';

import { GetNews } from "../../../utils/Endpoints";

const Homepage = () => {
    const globalStates = useHookState(GlobalStates);
    const [newsList, setNewsList] = useState(null);

    useEffect(() => {
        getNewsList();
    }, []);

    const getNewsList = () => {
        GetNews()
            .then(response => {
                // console.log(response?.data?.data)
                setNewsList(response?.data?.data)
            })
            .catch(error => {
                console.log(error)
            })

    }


    return (
        <div style={{ backgroundColor: ConstantStyles['light-grey'] }}>
            <Box sx={{
                backgroundColor: ConstantStyles['light-grey'],
                margin: "0 auto",
                maxWidth: "1440px",
                paddingLeft: { xs: "0px", sm: "0px", md: "220px" },
                minHeight: "850px",
                paddingTop: "20px",
                paddingBottom: "140px"
            }} >

                <CardsSection newsList={newsList} />

            </Box >
        </div>
    )


}


export default Homepage;