import * as React from 'react';
import { useState, useEffect, useMemo } from "react";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import navItems from '../../../utils/NavItems.json';
import ConstantStyle from '../../../styles/ConstantStyles.json'
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from "../../../utils/GlobalStates";
import { GetWallet, GetGcashWallet } from "../../../utils/Endpoints";
// import SiteLogo from '';

const drawerWidth = 230;

const handleRedirectLogin = () => {
    window.location.href = "/login";
}

export default function PermanentDrawerLeft() {
    const globalStates = useHookState(GlobalStates);
    const [activeTab, setActiveTab] = useState(globalStates.isMobile.get());
    const [mainBalance, setMainBalance] = useState('fetching...');
    const [isRefresh, setIsRefresh] = useState(0);
    let sideBarLeft = activeTab === false ? "-500px" : "";
    useEffect(() => {
        sideBarLeft = activeTab === false ? "-500px" : "";
    }, [globalStates.isMobile.get()]);

    useEffect(() => {
        if (localStorage.getItem('username')) {
            GetBalance()
        }
    }, [isRefresh]);

    // useEffect(() => {
    //     socket.on("message", function (e) {
    //         console.log(e);
    //     })

    //     // socket.on("newUserResponse", data => setUsers(data))
    //     console.log(socket)
    // }, [socket])

    // if (localStorage.getItem('username')) {
    //     setInterval(function () {
    //         GetBalance()
    //     }, 7000);
    // }

    const GetBalance = () => {
        GetWallet()
            .then(response => {
                if (response.data.data !== null) {
                    console.log(response.data.data.user_id)
                    localStorage.setItem("user_id", response.data.data.user_id)
                    setMainBalance(response.data.data.main_balance)
                }
                else {
                    setMainBalance("0.00")
                }
            })
            .catch(error => {
                console.log(error)
                // localStorage.clear()
                // Cookies.set('session-1', "")
                // globalStates.alertType.set("error");
                // globalStates.alertTitle.set("Error");
                // globalStates.alertBody.set("Session Expired. Logging out...");
                // globalStates.showAlert.set(true);
                // setTimeout(() => {
                //     window.location.href = "/";
                // }, 3000);
            })

    }


    return (
        <Box
            sx={{
                display: 'flex',
                backgroundColor: "#e8e8e8"
            }}
        >
            {globalStates.isMobile ?
                <div>
                    < p
                        style={{
                            padding: "25% 20%"
                        }}
                        onClick={() => {
                            setActiveTab(!activeTab)
                        }}
                    >
                        <MenuIcon /></p>
                </div> : null}
            <CssBaseline />
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxShadow: ConstantStyle['default-box-shadow'],
                        left: { xs: sideBarLeft || "", sm: sideBarLeft || "", md: 0, lg: 0, xl: 0 },
                        boxSizing: 'border-box',
                        overflow: "hidden",
                        transition: "all 0.5s"
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                < p style={{ textAlign: "right" }}
                    onClick={() => {
                        setActiveTab(!activeTab)
                    }}
                ><MenuOpenIcon sx={{ display: { xs: "unset", sm: "unset", md: "none", lg: "none", xl: "none" } }} /></p>
                <center>
                    {/* <h2>Logo Here</h2> */}
                    <img style={{ padding: "5%" }} src={process.env.PUBLIC_URL + '/megapanalo-logo.svg'} />

                </center>
                <br />
                <Divider />

                {localStorage.getItem("username") ?

                    <p style={{ textAlign: "center" }}>Hello, <b>{localStorage.getItem("username")}</b> !
                        <br /> Balance: <b>{mainBalance}</b></p>
                    :
                    <Button
                        onClick={handleRedirectLogin}
                        sx={{
                            // minWidth: "130px",
                            padding: "10px 0",
                            backgroundColor: ConstantStyle.primary,
                            color: ConstantStyle["white"],
                            borderRadius: "50px",
                            margin: { xs: '0 30px', sm: '0 30px', md: '0 30px', xl: '0 30px' },
                            fontSize: "16px",
                            fontWeight: ConstantStyle['medium-bold'],
                            backgroundImage: `url(${process.env.PUBLIC_URL + '/home/btnlogin.svg'})`,
                            "&:hover": {
                                backgroundColor: ConstantStyle.primary
                            }
                        }}
                    >

                        Login / Register
                    </Button>

                }


                <Divider />
                <List>
                    {navItems.map((text, index) => (
                        <a href={localStorage.getItem("username") ? text.url : text.loggedUrl} style={{ textDecoration: "none", color: ConstantStyle.black }}>
                            <ListItem key={text} disablePadding style={{ margin: "0 10%" }}>
                                <ListItemButton>
                                    {window.location.pathname === text.url ?
                                        <img style={{ padding: "5%" }} width="55" src={process.env.PUBLIC_URL + '/navbarIcons/' + text.icon_active} />
                                        :
                                        <img style={{ padding: "5%" }} width="55" src={process.env.PUBLIC_URL + '/navbarIcons/' + text.icon_active} />}
                                    <ListItemText primaryTypographyProps={{
                                        style: {
                                            fontSize: "18px",
                                            textTransform: "uppercase",
                                            color: window.location.pathname === text.url ? text.color : text.color,
                                            fontWeight: ConstantStyle['bold']
                                        }
                                    }}
                                        primary={text.title} />
                                </ListItemButton>
                            </ListItem>
                        </a>
                    ))}
                </List>
                <Divider />
            </Drawer>
            {/* :
                < p
                    onClick={() => {
                        setActiveTab(!activeTab)
                    }}
                >Toggle</p>
            } */}
        </Box >
    );
}