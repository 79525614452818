import { useState, useEffect } from "react";
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from '../../../../utils/GlobalStates';
import ConstantStyles from "../../../../styles/ConstantStyles.json";
import GameTabs from "../../../../utils/GameTabs";
import bingostar from '../../../../assets/img/bingostar.png';
import happybingo from '../../../../assets/img/happybingo.png';

import { LaunchLiveGame, LaunchBingoGame, GetPlayerGame } from "../../../../utils/Endpoints";

import {
    Grid,
    Card,
    CardContent,
    CardMedia
} from '@mui/material';

const Gamelist = (props) => {
    const globalStates = useHookState(GlobalStates);
    const [activeTab, setActiveTab] = useState("0");
    const [playerCount, setPlayerCount] = useState(null);

    useEffect(() => {
        setPlayerCount(props.playerCount ?? [])
    }, [props.playerCount]);

    const handleChange = (event, newValue) => {
        setActiveTab(event.target.id);
    };

    const LaunchThisGame = (game_id, type) => {
        playerActiveGame(type)
        LaunchLiveGame(game_id)
            .then(response => {
                if (localStorage.getItem("username")) {
                    window.location.href = response.data.data.url;
                }
                else {
                    window.location.href = "/login";
                }
            })
            .catch(error => {
                window.location.href = "/login";
                console.log(error)
            })
        setTimeout(() => {
            globalStates.showAlert.set(false);
        }, 2000);
    }

    const LaunchGame = (game_id, type) => {
        playerActiveGame(type)
        LaunchBingoGame(game_id)
            .then(response => {
                if (response.data.message === "Request failed with status code 404") {
                    globalStates.alertType.set("error");
                    globalStates.alertTitle.set("Error");
                    globalStates.alertBody.set(response.data.message);
                    globalStates.showAlert.set(true);
                }
                else {
                    // console.log(response)
                    window.location.href = response.data.data.url;
                }
            })
            .catch(error => {
                globalStates.alertType.set("error");
                globalStates.alertTitle.set("Error");
                globalStates.alertBody.set("Failed to retrieve live games");
                globalStates.showAlert.set(true);
                console.log(error)
            })
        setTimeout(() => {
            globalStates.showAlert.set(false);
        }, 2000);
    }

    const playerActiveGame = (type) => {
        GetPlayerGame(type)
            .then(response => {
                // console.log(response)
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <Grid container justifyContent="center" spacing={2} p={{ xs: 2, sm: 4 }} style={{ backgroundColor: ConstantStyles['light-grey'], paddingTop: "0px", paddingBottom: "30px" }} >

            <Grid item xs={12} sm={12}>
                <Card sx={{ maxWidth: "100%" }} style={{
                    backgroundColor: ConstantStyles.white,
                    color: ConstantStyles['dark-grey'], boxShadow: ConstantStyles['default-box-shadow2'], borderRadius: "12px"
                }}>
                    <CardContent
                        style={{ minHeight: "300px", padding: "15px 0px" }}
                    >
                        <Grid container spacing={0} pr={1} pl={1}>
                            {GameTabs.map((text, index) => (

                                <Grid item xs={12} sm={6} md={6} lg={3}
                                    key={index}
                                    sx={{
                                        textAlign: { xs: "center", sm: "center", padding: "0" }
                                    }}
                                    style={{
                                        cursor: "pointer"
                                    }}
                                >
                                    <div >
                                        {parseInt(activeTab) === parseInt(index) ?
                                            <div className="active_tabs" style={{
                                                borderBottom: "2px solid #eee"
                                            }}

                                                id={index}
                                                onClick={handleChange}>
                                                <img style={{ pointerEvents: "none", padding: "10px" }} width="50" src={process.env.PUBLIC_URL + '/Games/Icons/' + text.icon_active} />
                                                <span id="bottomSelected" style={{
                                                    pointerEvents: "none",
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                    color: text.color_active,
                                                }}>{text.title}</span>
                                                &nbsp;
                                                <span style={{
                                                    fontSize: "12px",
                                                    ointerEvents: "none",
                                                    color: text.color_active,
                                                    borderRadius: "50px",
                                                    fontWeight: "bold",
                                                    padding: "2px",
                                                    backgroundColor: text.background
                                                }}> <img style={{ pointerEvents: "none" }} width="20" src={process.env.PUBLIC_URL + '/Games/Icons/' + text.user_active} />
                                                    &nbsp;&nbsp;
                                                    {playerCount !== "undefined" ?
                                                        text.value === "live-bingo" ? playerCount?.['live-bingo']?.count || 0 : ""
                                                        : 0
                                                    }

                                                    {playerCount !== "undefined" ?
                                                        text.value === "bingo" ? playerCount?.['bingo']?.count || 0 : ""
                                                        :
                                                        0
                                                    }
                                                    {playerCount !== "undefined" ?
                                                        text.value === "sportsbook" ? 0 : ""
                                                        :
                                                        0
                                                    }
                                                    {playerCount !== "undefined" ?
                                                        text.value === "casino" ? playerCount?.['casino']?.count || 0 : ""
                                                        :
                                                        0
                                                    }
                                                </span>

                                            </div>
                                            :
                                            <div style={{
                                                borderBottom: "2px solid #eee"
                                            }}

                                                id={index}
                                                onClick={handleChange}>
                                                <img style={{ pointerEvents: "none", padding: "10px" }} width="50" src={process.env.PUBLIC_URL + '/Games/Icons/' + text.icon_disabled} />
                                                <span style={{
                                                    fontSize: "16px",
                                                    pointerEvents: "none",
                                                    color: text.color
                                                }}>{text.title}</span>
                                                &nbsp;
                                                <span style={{
                                                    fontSize: "12px",
                                                    pointerEvents: "none",
                                                    fontWeight: "bold",
                                                    padding: "2px",
                                                    color: text.color
                                                }}> <img style={{ pointerEvents: "none" }} width="20" src={process.env.PUBLIC_URL + '/Games/Icons/numusericon-disabled.svg'} />
                                                    &nbsp;&nbsp;
                                                    {playerCount !== "undefined" ?
                                                        text.value === "live-bingo" ? playerCount?.['live-bingo']?.count || 0 : ""
                                                        : 0}
                                                    {playerCount !== "undefined" ?
                                                        text.value === "bingo" ? playerCount?.['bingo']?.count || 0 : ""
                                                        :
                                                        0}
                                                    {playerCount !== "undefined" ?
                                                        text.value === "sportsbook" ? 0 : ""
                                                        :
                                                        0}
                                                    {playerCount !== "undefined" ?
                                                        text.value === "casino" ? playerCount?.['casino']?.count || 0 : ""
                                                        :
                                                        0}
                                                </span>
                                            </div>}
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                        <br />
                        {activeTab === "0" ?
                            <Grid container spacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }} pr={{ xs: 1, sm: 1, md: 1, lg: 4, xl: 4 }} pl={{ xs: 1, sm: 1, md: 1, lg: 4, xl: 4 }}
                                style={{ paddingTop: "20px", paddingBottom: "100px" }} >
                                {props.gamesLive.map((item, index) => (
                                    <Grid item xs={4} sm={2.5} key={index} >

                                        <Card
                                            sx={{ maxWidth: "100%" }}
                                            style={{
                                                backgroundColor: "transparent",
                                                color: ConstantStyles.white,
                                                cursor: "pointer",
                                                border: "unset",
                                                minHeight: "152.6px"
                                            }}

                                            onClick={() => {
                                                let game_id = item.game_id
                                                LaunchThisGame(game_id, "live-bingo")
                                                // window.location = `https://live.888bingo.ph/api/games/live-bingo/${item.game_id}`
                                                // window.open(`https://uat.888bingo.ph${item.href}`, `_blank`);
                                            }}

                                        >
                                            {item.game_id === 14110953 || item.room_id === 89 ?
                                                <CardMedia
                                                    component="img"
                                                    image={happybingo}
                                                    alt={item.room_name}
                                                    sx={{
                                                        objectFit: "unset",
                                                        padding: "0%",
                                                        border: "0px solid",
                                                        color: "#222"
                                                    }}
                                                /> : null
                                            }
                                            {item.game_id === 13874164 || item.room_id === 91 ?
                                                <CardMedia
                                                    component="img"
                                                    image={bingostar}
                                                    alt={item.room_name}
                                                    sx={{
                                                        objectFit: "unset",
                                                        padding: "0%",
                                                        border: "0px solid",
                                                        color: "#222"
                                                    }}
                                                /> : null
                                            }
                                            {item.game_id === 13871504 || item.room_id === 90 ?
                                                <CardMedia
                                                    component="img"
                                                    image={"https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/330px-No-Image-Placeholder.svg.png?20200912122019"}
                                                    alt={item.room_name}
                                                    sx={{
                                                        objectFit: "unset",
                                                        padding: "0%",
                                                        border: "0px solid",
                                                        color: "#222",
                                                        height: "170px"
                                                    }}
                                                /> : null
                                            }
                                            {/* </CardActionArea> */}
                                            <p style={{ padding: "10px" }}>{item.room_name}</p>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>

                            : null}
                        {activeTab === "1" ?
                            <Grid container spacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }} pr={{ xs: 1, sm: 1, md: 1, lg: 4, xl: 4 }} pl={{ xs: 1, sm: 1, md: 1, lg: 4, xl: 4 }}
                                style={{ paddingTop: "10px", paddingBottom: "10px" }} >

                                {props.gamesClassic.map((item, index) => (
                                    <Grid item xs={4} sm={2} key={index} >

                                        <Card
                                            sx={{ maxWidth: "100%" }}
                                            style={{
                                                backgroundColor: "transparent",
                                                color: ConstantStyles.white,
                                                cursor: "pointer",
                                                border: "unset"
                                            }}
                                            onClick={() => {
                                                // href_lobby
                                                // alert(item.href)
                                                // alert(localStorage.getItem("username"))
                                                localStorage.getItem("username") ? LaunchGame(item.name, "bingo") : window.location.href = `https://uat.888bingo.ph${item.href}`;

                                            }}

                                        >
                                            <CardMedia
                                                component="img"
                                                image={process.env.PUBLIC_URL + item.logo}
                                                alt={item.title}
                                                sx={{
                                                    objectFit: "unset",
                                                    padding: "0%"
                                                }}
                                            />
                                            {/* </CardActionArea> */}
                                        </Card>
                                    </Grid>
                                ))}

                                {props.gamesVariant.map((item, index) => (
                                    item.title !== "Bingo Fortune 21" ?
                                        <Grid item xs={4} sm={2} key={index} >

                                            <Card
                                                sx={{ maxWidth: "100%" }}
                                                style={{
                                                    backgroundColor: "transparent",
                                                    color: ConstantStyles.white,
                                                    cursor: "pointer",
                                                    border: "unset"
                                                }}

                                                onClick={() => {

                                                    // LaunchGame(item.name)
                                                    localStorage.getItem("username") ? LaunchGame(item.name, item.name) : window.location.href = `https://888bingo.ph${item.href}`;

                                                    // window.open(`https://888bingo.ph${item.href}`, `_blank`);
                                                }}

                                            >

                                                <CardMedia
                                                    component="img"
                                                    image={process.env.PUBLIC_URL + item.logo}
                                                    alt={item.title}
                                                    sx={{
                                                        objectFit: "unset",
                                                        padding: "0%",
                                                        border: "0px solid"
                                                    }}
                                                />
                                                {/* </CardActionArea> */}
                                            </Card>
                                        </Grid>
                                        : null
                                ))}
                            </Grid> : null}

                        {/* <Grid container spacing={0} pr={3} pl={3}  >
                            {GameListDemo.map((text, index) => (
                                <Grid item xs={12} sm={2}>

                                    <Card sx={{ maxWidth: "100%" }} style={{
                                        backgroundColor: "transparent",
                                        border: "0px solid",
                                        boxShadow: "unset",
                                        color: ConstantStyles['dark-grey'],
                                        minHeight: "180px"
                                    }}>
                                        <CardContent sx={{
                                            padding: 0,
                                            "&:last-child": {
                                                paddingBottom: 0
                                            }
                                        }}>
                                            <img height="150" src={process.env.PUBLIC_URL + '/Games/' + text.icon_active} />

                                        </CardContent>
                                    </Card>

                                </Grid>
                            ))}
                        </Grid> */}
                        {activeTab === "2" ?
                            // <p>Casino Games</p>
                            null
                            : null}
                        {activeTab === "3" ?
                            // <p>Sportsbook</p>
                            null
                            : null}


                    </CardContent>
                </Card>
            </Grid>
        </Grid >
    )


}


export default Gamelist;