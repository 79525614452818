import { useState, useEffect, useMemo } from "react";
import { useState as useHookState } from "@hookstate/core";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import Cookies from 'js-cookie';
// shared components
import CustomAlert from "./components/shared/CustomAlert";
import CustomCircularProgress from './components/shared/CustomCircularProgress';
// components
import SideBar from './components/shared/general/SideBar';
import Footer from './components/shared/general/Footer';

// modules
import Homepage from './components/modules/Home/Homepage';
import Games from './components/modules/Home/Games';
import News from './components/modules/Home/News';
import Promo from './components/modules/Home/Promo';
import Login from './components/modules/authentication/Login';
import AccountSettings from './components/modules/Accounts/AccountSettings';

// styles
import ThemeStyles from "./styles/ThemeStyles";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import socketIO from "socket.io-client"
// global states
import GlobalStates from './utils/GlobalStates';
// general components

const socket = socketIO.connect("https://socket.megabingo.ph/megabingo-events")
function App(props) {
	const globalStates = useHookState(GlobalStates);

	const [theme, setTheme] = useState(ThemeStyles);

	useEffect(() => {
		if (!Cookies.get('session-1') || Cookies.get('session-1') === "") {
			localStorage.clear()
		}

		if (window.screen.width <= 800) {
			globalStates.isMobile.set(true);
		}
		else {
			globalStates.isMobile.set(false);
		}

	}, [window.screen.width]);



	return (
		<ThemeProvider theme={theme}>
			{/* {window.location.pathname !== "/login" ? <SideBar /> : ""} */}

			{localStorage.getItem("username") ?
				<>
					<SideBar />
					<BrowserRouter basename={props.baseUrl}>
						<Routes>
							<Route path="*" element={<Navigate to="/" />} />
							<Route path="/" element={<Homepage socket={socket} />} />
							<Route path="/games" element={<Games />} />
							<Route path="/news" element={<News />} />
							<Route path="/promo" element={<Promo />} />
							<Route path="/account" element={<AccountSettings />} />

						</Routes>

					</BrowserRouter>
				</>
				:
				<>
					{window.location.pathname !== "/login" ? <SideBar /> : ""}
					<BrowserRouter basename={props.baseUrl}>
						<Routes>
							<Route path="/" element={<Homepage socket={socket} />} />
							<Route path="/login" element={<Login />} />
							<Route path="/games" element={<Games />} />
							<Route path="/news" element={<News />} />
							<Route path="/promo" element={<Promo />} />
							<Route path="*" element={<Navigate to="/login" />} />
							<Route path="/account" element={<AccountSettings />} />
							{/* <Route path="/promo" element={<News to="/" />} /> */}
							{/* <Route path="/account" element={<Login />} /> */}
							{/* <Route path="*" element={<Navigate to="/login" />} /> */}
						</Routes>
					</BrowserRouter>
				</>
			}

			<CustomAlert />
			<Footer />
		</ThemeProvider>
	);
}

export default App;
