import React from 'react';
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from '../../../utils/GlobalStates';
import Grid from '@mui/material/Grid';

import ConstantStyles from '../../../styles/ConstantStyles.json';
// import bredas_logo from '../../../assets/img/megapanalo-logo.svg';

import socmed from '../../../assets/img/SocialMedia/socmed.jpg';
import appstore from '../../../assets/img/SocialMedia/btn-appstore.png';
import googleplay from '../../../assets/img/SocialMedia/btn-googleplay.png';
import pagcor from '../../../assets/img/section/pagcor_logo.png';
import keepitfun from '../../../assets/img/section/21_keep_it_fun_shorter.png';

const Footer = () => {
    const globalStates = useHookState(GlobalStates);
    // useEffect(() => {
    // }, []);

    return (
        <Grid
            container
            spacing={2}
            p={{ xs: 2, sm: 4 }}
            style={{
                paddingRight: "0px",
                paddingBottom: "25px",
                color: ConstantStyles['dark-grey'],
                borderBottom: ConstantStyles['default-border'],
                backgroundColor: "rgb(0 166 252)"
                // zIndex: "2000",
                // position: "absolute"
            }}
            sx={{
                paddingTop: { xs: "0px", sm: "25px" },
            }}

        >


            <Grid
                container
                spacing={2}
                justifyContent="center"
                p={{ xs: 2, sm: 4 }}
                style={{
                    paddingBottom: "25px",
                    color: ConstantStyles['dark-grey'],
                    borderBottom: ConstantStyles['default-border'],
                }}
                sx={{
                    paddingTop: { xs: "0px", sm: "25px" },
                    textAlign: { xs: "center", sm: "unset" }
                }}

            >
                <Grid item xs={12} sm={0} md={2}>
                </Grid>

                <Grid item xs={12} sm={3} md={2}>

                    <p className="antonioFont" style={{ color: ConstantStyles.white, fontWeight: ConstantStyles['bold'] }}>ABOUT</p>

                    <a style={{ color: ConstantStyles.white, textDecoration: "none", display: "block", fontSize: "13px" }} href={"/terms-of-use"}>Terms of use</a>

                    <a style={{ color: ConstantStyles.white, textDecoration: "none", display: "block", fontSize: "13px" }} href={"/privacy-policy"}>Privacy Policy</a>

                    {/* {navItems.map((item, index) => (
                        <a key={index} style={{ color: ConstantStyles.white, textDecoration: "none", display: "block", fontSize: "14px" }} to={item.url}>{item.title}</a>
                    ))} */}

                </Grid>

                <Grid item xs={12} sm={3} md={2}>

                    <p className="antonioFont" style={{ color: ConstantStyles.white, fontWeight: ConstantStyles['bold'] }}>CONTACT</p>

                    <p style={{ color: ConstantStyles.white, textDecoration: "none", display: "block", fontSize: "13px" }} >cs@megabingo.com</p>

                    <p style={{ color: ConstantStyles.white, textDecoration: "none", display: "block", fontSize: "13px" }} >09676554412</p>

                </Grid>
                <Grid item xs={12} sm={6} sx={{ textAlign: "center" }}>
                    <p className="antonioFont" style={{ color: ConstantStyles.white, fontWeight: ConstantStyles['bold'] }}>APP</p>

                    <img style={{ pointerEvents: "none", marginRight: "5px", marginTop: "3px" }} width="120" src={appstore} />
                    <img style={{ pointerEvents: "none", marginRight: "5px", marginTop: "3px" }} width="120" src={googleplay} />


                </Grid>
            </Grid>

            <Grid container spacing={2} p={{ xs: 0, sm: 8 }}
                justifyContent="center" style={{ paddingTop: "25px", paddingBottom: "0px", color: ConstantStyles['white'] }}>

                <Grid item xs={12} sm={2} md={3} className="listFooter" style={{ textAlign: "center" }}>
                    <img style={{ pointerEvents: "none", float: "right", marginRight: "5px", marginTop: "3px" }} width="120" src={keepitfun} />
                </Grid>
                <Grid item xs={12} sm={8} md={6} className="listFooter" style={{ textAlign: "center", color: ConstantStyles['white'] }}>
                    <p style={{ fontSize: "14px", textAlign: "center", color: ConstantStyles['white'] }}>This website offers gaming with risk experience. To be a user of our site you must be over 21 years old. We are not responsible for the violation of the local laws. Play responsibly and have fun on Mega Bigo</p>
                    <br />
                    <b style={{ fontSize: "12px" }} >The first and only live streaming bingo in the Philippines</b>
                    <p style={{ fontSize: "10px", color: ConstantStyles['white'] }}>Copyright ©2021-2071 Mega Bigo. All rights reserved.</p>
                </Grid>
                <Grid item xs={12} sm={2} md={3} className="listFooter" style={{ textAlign: "center" }}>
                    <img style={{ pointerEvents: "none", float: "left", marginRight: "5px", marginTop: "3px" }} width="40" src={pagcor} />
                </Grid>

            </Grid>

        </Grid >
    )


}


export default Footer;