import { createState } from '@hookstate/core';

const GlobalStates = createState({
    isLoading: true,
    isLoggedIn: false,
    isMobile: false,
    isTablet: false,

    showAlert: false,
    alertTimer: null,
    alertType: "success",
    alertTitle: "Success",
    alertBody: "Success",

    showFilterSidebar: false,
    showConfirmationModal: false,

    selectedNumber1: [],
    subscriberPlans: [],
    subscriberStatuses: []
});

export default GlobalStates;