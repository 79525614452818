import { useState } from "react";
import {
    TableContainer,
    Table,
    TableHead,
    TableSortLabel,
    TableBody,
    TableRow,
    TableCell,
    TablePagination,
    Box,
    Checkbox
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import CustomCircularProgress from "./CustomCircularProgress";
import ConstantStyles from "../../styles/ConstantStyles.json";

function CustomTable(props) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState(props.order);
    const [orderBy, setOrderBy] = useState(props.orderBy);

    function descendingComparator(a, b, orderBy) {
        if ([null, undefined].includes(a[orderBy]) || [null, undefined].includes(b[orderBy])) {
            let tempA = [null, undefined].includes(a[orderBy]) ? -1 : a[orderBy];
            let tempB = [null, undefined].includes(b[orderBy]) ? -1 : b[orderBy];
            return tempA.toString().localeCompare(tempB.toString(), undefined, {numeric: true, sensitivity: 'base'});
        }

        return a[orderBy].toString().localeCompare(b[orderBy].toString(), undefined, {numeric: true, sensitivity: 'base'});
    }
      
    function getComparator(order, orderBy) {
        return order === 'desc' 
        ? (a, b) => -descendingComparator(a, b, orderBy)
        : (a, b) => descendingComparator(a, b, orderBy);
    }
      
    // This method is created for cross-browser compatibility, if you don't
    // need to support IE11, you can use Array.prototype.sort() directly
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);

        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });

        return stabilizedThis.map((el) => el[0]);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const handleCheckboxChange = (row) => {
        let rowIndex = props.selectedData.findIndex(d => d.id === row.id);
        let newSelecteds = [...props.selectedData];

        if (rowIndex < 0) {
            newSelecteds.push(row);
        } else {
            newSelecteds.splice(rowIndex, 1);
        }

        props.setSelectedData(newSelecteds);
    };

    const handleSelectAllClick = (event) => {
        let newSelecteds = [];

        if (event.target.checked) {
            newSelecteds = [...props.data];
        }

        props.setSelectedData(newSelecteds);
    };

    function EnhancedTableHead(props) {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headers, checkbox } = props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };
      
        return (
            <TableHead id={`tablehead-${props.id}`} sx={{ borderRadius: "5px 5px 0px 0" }}>
                <TableRow
                    id={`tablehead-tablerow-${props.id}`}
                    sx={{ 
                        "td, th": { borderBottom: ConstantStyles["default-border"] },
                        borderRadius: "5px 5px 0px 0",
                    }}
                >
                    {checkbox
                    && <TableCell
                        id={`tablecell-checkbox`}
                        padding="checkbox"
                        sx={{ 
                            textAlign: "center",
                            padding: "0px"
                        }}
                    >
                        <Checkbox
                            id="checkbox"
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            sx={{ color: `${ConstantStyles.primary} !important`, margin: "auto" }}
                        />
                    </TableCell>}
                    {headers.map((header, index) => (
                        <TableCell
                            id={`tablecell-${header.id}`}
                            sortDirection={orderBy === header.id ? order : false}
                            sx={{ 
                                width: header.width ?? "auto", 
                                textAlign: header.align ?? "",
                                padding: (header.checkbox ?? false) ? "0px 12px" : "12px"
                            }}
                            padding={(header.checkbox ?? false) && "checkbox"}
                        >
                            {!header.sortable ? header.name
                            : <TableSortLabel
                                id={`tablesortlabel-${header.id}`}
                                active={orderBy === header.id}
                                direction={orderBy === header.id ? order : 'asc'}
                                onClick={createSortHandler(header.id)}
                            >
                                <span id={`tablesortlabel-span-${header.id}`}>{header.name}</span>
                                {orderBy === header.id ? (
                                <Box id={`tablesortlabel-box-${header.name}`} component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                                ) : null}
                            </TableSortLabel>}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    return (
        <div 
            style={{
                backgroundColor: ConstantStyles.white,
                border: ConstantStyles["default-border"],
                borderRadius: ConstantStyles["default-border-radius"],
                width: props.width
            }}
        >
            <TableContainer 
                id={`tablecontainer-${props.id}`} 
                sx={{ 
                    minHeight: (!props.isLoading && (rowsPerPage > 0 ? stableSort(props.data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : stableSort(props.data, getComparator(order, orderBy))).length > 0) ? props.minHeight : "0px",
                    maxHeight: props.maxHeight,
                    borderRadius: ConstantStyles["default-border-radius"]
                }}
            >
                <Table id={`table-${props.id}`} style={{ width: "100%" }} stickyHeader>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        numSelected={props.selectedData.length}
                        rowCount={props.data.length}
                        onSelectAllClick={handleSelectAllClick}
                        headers={props.headers}
                        checkbox={props.checkbox}
                    />
                    {(!props.isLoading && (rowsPerPage > 0 ? stableSort(props.data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : stableSort(props.data, getComparator(order, orderBy))).length > 0)
                    && <TableBody id={`tablebody-${props.id}`}>
                        {(rowsPerPage > 0 ? stableSort(props.data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : stableSort(props.data, getComparator(order, orderBy))).map((row, index) => (
                            <>
                                <TableRow
                                    id={`tablerow-${row.id}`}
                                    hover
                                    sx={{ 
                                        "&:hover": { backgroundColor: `${ConstantStyles["light-grey"]} !important` },
                                        "td, th": { borderBottom: ConstantStyles["default-border"] },
                                    }}
                                >   
                                    {props.checkbox
                                    && <TableCell 
                                        id={`tablecell-checkbox`} 
                                        padding="checkbox" 
                                        sx={{ 
                                            textAlign: "center",
                                            padding: "0px"
                                        }}
                                    >
                                        <Checkbox
                                            id={`checkbox-${row.id}`}
                                            color="primary"
                                            checked={props.selectedData.find(d => d.id === row.id) ?? false}
                                            onChange={() => handleCheckboxChange(row)}
                                            sx={{ color: `${ConstantStyles.primary} !important` }}
                                        />
                                    </TableCell>}
                                    {Object.keys(row).filter(key => props.headers.map(h => h.id).includes(key)).map((key, index2) => {
                                        return (
                                            <TableCell
                                                id={`tablecell-${row.id}-${key}`}
                                                padding={(props.headers.find(h => h.id === key)?.checkbox ?? null) !== null && "checkbox"}
                                                sx={{ 
                                                    textAlign: (props.headers.find(h => h.id === key)?.align ?? null) !== null ? props.headers.find(h => h.id === key).align : "",
                                                    padding: (props.headers.find(h => h.id === key)?.checkbox ?? null) !== null ? "0px 12px" : "12px"
                                                }}
                                            >
                                                {row[key]}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </>
                        ))}
                    </TableBody>}
                </Table>
            </TableContainer>
            {props.isLoading && <CustomCircularProgress width={"100%"} height={"300px"} size={30}/>}
            {(!props.isLoading && (rowsPerPage > 0 ? stableSort(props.data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : stableSort(props.data, getComparator(order, orderBy))).length <= 0)
            && <div style={{ width: "100%", height: "300px", display: "flex", alignItems: "center", justifyContent: "center" }}>{props.noDataText}</div>}
            {(!props.isLoading && (rowsPerPage > 0 ? stableSort(props.data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : stableSort(props.data, getComparator(order, orderBy))).length > 0)
            && <Table id="table-pagination" sx={{ marginTop: "-1px" }}>
                <TableBody id={`table-pagination-${props.id}`}>
                    <TableRow id={`tablerow-pagination-${props.id}`}>
                        <TablePagination
                            id={`tablepagination-${props.id}`}
                            rowsPerPageOptions={[5, 10, 25, 50, 100, { label: 'All', value: -1 }]}
                            count={props.data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            sx={{ 
                                border: "none",
                                borderTop: ConstantStyles["default-border"],
                            }}
                        />
                    </TableRow>
                </TableBody>
            </Table>}
        </div>
    )
}

CustomTable.propTypes = {
    id: PropTypes.string,
    order: PropTypes.string,
    orderBy: PropTypes.string,
    headers: PropTypes.array,
    data: PropTypes.array,
    minHeight: PropTypes.string,
    maxHeight: PropTypes.string,
    checkbox: PropTypes.bool,
    selectedData: PropTypes.array,
    setSelectedData: PropTypes.func,
    width: PropTypes.string,
    isLoading: PropTypes.bool,
    noDataText: PropTypes.string
};

CustomTable.defaultProps = {
    id: "",
    order: "desc",
    orderBy: "",
    headers: [],
    data: [],
    minHeight: "400px",
    maxHeight: "600px",
    checkbox: false,
    selectedData: [],
    setSelectedData: () => {},
    width: "100%",
    isLoading: false,
    noDataText: "No Data Found"
};

export default CustomTable;

// SAMPLE
// headers: [
//     {
//         id: "fileName",
//         name: "File Name",
//         sortable: true,
//         width: "50%"
//     },
//     {
//         id: "records",
//         name: "Records",
//         sortable: true,
//         width: "30%"
//     },
//     {
//         id: "delete",
//         name: "Delete",
//         align: "center",
//         sortable: false,
//         checkbox: true
//     }
// ]
// data: [
//     {
//         id: 1,
//         fileName: "Sample 1",
//         records: 1,
//         delete: image or icon
//     },
//     {
//         id: 2,
//         fileName: "Sample 2",
//         records: 2,
//         delete: image or icon
//     },
//     {
//         id: 3,
//         fileName: "Sample 3",
//         records: 3,
//         delete: image or icon
//     }
// ]
// order: desc or asc
// orderBy: header id
// checkbox: if true, selectedData and setSelectedData are required