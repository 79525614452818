/*******************************************************************************************
//  * File Name                : Login
//  * File Directory           : src/components/module/authentication/Login
//  * Module                   : Login
//  * Author                   : Joseph
//  * Version                  : 1
//  * Purpose                  : Reusable
//  * Used In				   : Home 
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   Login        -   Login maintenance page
 *
 * #How to call:
 *    <Login/>
 *
 *******************************************************************************************/
import Cookies from 'js-cookie';
import { useState } from "react";
import { useState as useHookState } from "@hookstate/core";
import CustomTextField from "../../shared/CustomTextField";
import CustomButton from "../../shared/CustomButton";
import ConstantStyles from "../../../styles/ConstantStyles.json";
import GlobalStates from "../../../utils/GlobalStates";
import { SendOTP, VerifyOTP, LoginPlayer } from "../../../utils/Endpoints";
// import SiteLogo from '../../../assets/img/megapanalo-logo.svg'

const Login = () => {
    const globalStates = useHookState(GlobalStates);
    const [number, setNumber] = useState("");
    const [otp, setOtp] = useState("");
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [loginType, setLoginType] = useState("1");
    const [userName, setUsername] = useState("");
    const [password, sePassword] = useState("");
    const [format, setFormat] = useState("");


    function containsOnlyNumbers(str) {
        return /^[0-9]+$/.test(str);
    }

    const handleSendOTP = () => {
        // alert("otp"); SendOTP

        let isEmail = /^\S+@\S+\.\S+$/.test(number);
        let isMobile = isEmail ? false : number.length == 11 && number[0] == 0 && Number(number);
        let type = null;

        if (isEmail) {
            type = "email";
            globalStates.alertBody.set("Successfully sent an OTP");
            globalStates.alertType.set("success");
            globalStates.alertTitle.set("Success");
            let body = {
                "type": type,
                "data": {
                    "username": number,
                    "type": "register"
                }
            }
            submitOTP(body)
        }
        else if (isMobile) {
            if (isMobile) {
                type = "mobile";
                let body = {
                    "type": type,
                    "data": {
                        "username": number,
                        "type": "register"
                    }
                }
                submitOTP(body)
            }
        }
        else if (number.length > 11 && number[0] !== 0 && Number(number)) {

            globalStates.alertBody.set("Invalid Format, format should be: 09XXXXXXX");
            globalStates.alertType.set("error");
            globalStates.alertTitle.set("Error");

        }
        else if (number.length < 11 && number[0] !== 0 && Number(number)) {

            globalStates.alertBody.set("Invalid Format, format should be: 09XXXXXXX");
            globalStates.alertType.set("error");
            globalStates.alertTitle.set("Error");

        }
        else {
            globalStates.alertBody.set("Invalid Format, format should be: user@email.com");
            globalStates.alertType.set("error");
            globalStates.alertTitle.set("Error");
        }

        globalStates.showAlert.set(true);
        setTimeout(() => {
            globalStates.showAlert.set(false);
        }, 2000);
    }

    const handleSubmit = () => {
        if (number !== "" && otp !== "") {
            setIsLoggingIn(true);

            let body = {
                "data": {
                    "username": number,
                    "otp": otp,
                    "type": "register"
                }
            };
            otpVerification(body)
            // window.location.href = "/";
        }
        else {
            globalStates.alertType.set("error");
            globalStates.alertTitle.set("Error");
            globalStates.alertBody.set("Please input username and password.");
            globalStates.showAlert.set(true);

            setTimeout(() => {
                globalStates.showAlert.set(false);
            }, 3000);
        }
    }

    const submitOTP = (body) => {
        SendOTP(body)
            .then(response => {

                globalStates.alertBody.set("Successfully sent an OTP.");
                globalStates.alertType.set("success");
                globalStates.alertTitle.set("Success");
                globalStates.showAlert.set(true);

            })
            .catch(error => {
                // console.log(error);
                setIsLoggingIn(false);

                if ((error?.response?.data?.message ?? "").toLowerCase().includes("incorrect")) {
                    globalStates.alertBody.set("Incorrect email or password.");
                }
                else {
                    globalStates.alertBody.set("Failed to log in, please try again later.");
                }

                globalStates.alertType.set("error");
                globalStates.alertTitle.set("Error");
                globalStates.showAlert.set(true);

            });

        setTimeout(() => {
            globalStates.showAlert.set(false);
        }, 2000);
    }

    const otpVerification = (body) => {
        VerifyOTP(body)
            .then(response => {
                Cookies.set('session-1', response.data.data.token)
                localStorage.setItem("username", response.data.data.data.username);
                localStorage.setItem("info", JSON.stringify(response.data.data));
                globalStates.alertBody.set("Please wait ...");
                globalStates.alertType.set("success");
                globalStates.alertTitle.set("Success");
                globalStates.showAlert.set(true);

                setTimeout(() => {
                    globalStates.showAlert.set(false);
                    window.location.href = "/";
                }, 2000);
            })
            .catch(error => {
                setIsLoggingIn(false);

                globalStates.alertType.set("error");
                globalStates.alertBody.set("Failed to log in, kindly double check otp or try again later");
                globalStates.alertTitle.set("Error");
                globalStates.showAlert.set(true);

                setTimeout(() => {
                    globalStates.showAlert.set(false);
                }, 2000);
            });

    }

    const handleLoginType = (type) => {
        setLoginType(type)
        formatChecker("")
    }

    const playerVerify = (body) => {
        LoginPlayer(body)
            .then(response => {

                // console.log(response.data.status)
                if (response.data.status !== 0) {
                    Cookies.set('session-1', response.data.data.token)
                    localStorage.setItem("username", response.data.data.data.username);
                    localStorage.setItem("info", JSON.stringify(response.data.data));
                    globalStates.alertBody.set("Please wait ...");
                    globalStates.alertType.set("success");
                    globalStates.alertTitle.set("Success");
                    globalStates.showAlert.set(true);
                    setTimeout(() => {
                        globalStates.showAlert.set(false);
                        window.location.href = "/";
                    }, 2000);
                }
                else if (response.data.status === 0) {

                    globalStates.alertType.set("error");
                    globalStates.alertBody.set(response.data.message);
                    globalStates.alertTitle.set("Error");
                    globalStates.showAlert.set(true);
                    setTimeout(() => {
                        globalStates.showAlert.set(false);
                        // window.location.href = "/";
                    }, 2000);
                }

            })
            .catch(response => {
                setIsLoggingIn(false);
                setTimeout(() => {
                    globalStates.showAlert.set(false);
                }, 2000);
            });

    }

    const handleLoginPassword = () => {
        // alert("otp"); SendOTP

        let isEmail = /^\S+@\S+\.\S+$/.test(userName);
        let isMobile = isEmail ? false : userName.length == 11 && userName[0] == 0 && Number(userName);
        let isUsername = !isEmail && !isMobile;
        let type = null;
        let body;
        if (isEmail) {
            type = "email";
            globalStates.alertBody.set("Successfully sent an OTP.");
            globalStates.alertType.set("success");
            globalStates.alertTitle.set("Success");
            body = {
                "data": {
                    "type": type,
                    "username": userName,
                    "password": password
                }
            }
            playerVerify(body)
        }
        else if (isMobile) {
            if (isMobile) {
                type = "mobile";
                body = {
                    "data": {
                        "type": type,
                        "username": userName,
                        "password": password
                    }
                }
                playerVerify(body)
            }

        }
        else if (isUsername) {
            type = "username";
            body = {
                "data": {
                    "type": type,
                    "username": userName,
                    "password": password
                }
            }
            playerVerify(body)
        }
        else {
            globalStates.alertBody.set("Player Not found! Kindly try again or contact admin");
            globalStates.alertType.set("error");
            globalStates.alertTitle.set("Error");
        }

        // globalStates.showAlert.set(true);
        setTimeout(() => {
            globalStates.showAlert.set(false);
        }, 2000);
    }

    const handleKeypress = e => {
        // console.log(e.which)
        //it triggers by pressing the enter key
      if (e.which === 13) {
        handleLoginPassword();
      }

    };
    
    const formatChecker = (e) => {
        let isEmail = /^\S+@\S+\.\S+$/.test(e);
        // alert(e)
        if (e === "") {
            setFormat("");
        }
        else if (isEmail) {
            setFormat("");
        }
        else if (e[0] == 0 && Number(e)) {

            if (e.length == 11) {
                setFormat("");
            }
            else {
                setFormat("Invalid Format, format should be: 09XXXXXXXXX");
            }
        }
        else {
            setFormat("Invalid Format, format should be: user@email.com");
        }
    }

    return (
        <div className="login" style={{
            minHeight: "750px"
        }}>
            <div className="login-container">
                {/* <img src={SiteLogo} /> */}

                <div className="login-form">
                    <a href="/" style={{
                        color: ConstantStyles.primary,
                        fontSize: "26px",
                        fontWeight: 700,
                        textAlign: "center",
                        width: "100%",
                        margin: "0 auto",
                        display: "block"
                    }}>
                        <img style={{ padding: "5%" }} src={process.env.PUBLIC_URL + '/megapanalo-logo.svg'} />
                    </a>

                    <center><b>Login via:</b></center>
                    <br />
                    <CustomButton
                        buttonLabel={"Via OTP"}
                        onClick={() => {
                            handleLoginType("1")
                            setNumber("")
                            setUsername("")
                        }
                        }
                        type="submit"
                        customSX={{
                            width: "50%",
                            padding: "6px 15px",
                            color: loginType === "1" ? ConstantStyles.white : "#222",
                            backgroundColor: loginType === "1" ? ConstantStyles.primary : "transparent",
                            border: ConstantStyles['default-border'],
                            "&:hover": {
                                color: ConstantStyles.white,
                                boxShadow: "none"
                            },
                        }}
                    />
                    <CustomButton
                        buttonLabel={"Via Password"}
                        onClick={() => {
                            handleLoginType("2")
                            setNumber("")
                            setUsername("")
                        }}
                        type="submit"
                        customSX={{
                            width: "50%",
                            padding: "6px 15px",
                            color: loginType === "2" ? ConstantStyles.white : "#222",
                            backgroundColor: loginType === "2" ? ConstantStyles.primary : "transparent",
                            border: ConstantStyles['default-border'],
                            "&:hover": {
                                color: ConstantStyles.white,
                                boxShadow: "none"
                            },
                        }}
                    />
                    <br />
                    <br />
                    {loginType === "1" ?
                        <>
                            <p style={{ fontSize: "16px", marginBottom: "5px" }}>Phone / Email <span style={{ fontWeight: "bold", color: "red" }}>* </span></p>
                            <CustomTextField
                                value={number}
                                onChange={(e) => {
                                    setNumber(e)
                                    formatChecker(e)
                                }}
                                placeholder={"Enter Phone / Email"}
                                type="text"
                                id="email"
                                disabled={isLoggingIn}
                                customSX={{
                                    padding: "6px 15px",
                                    marginBottom: format ? "0px" : "20px",
                                    border: format ? "1px solid red" : "1px solid #D0D0D0"
                                }}
                            />
                            <small style={{ fontSize: "12px", color: "red" }}>{format}</small>

                            <p style={{ fontSize: "16px", marginBottom: "5px" }}>OTP <span style={{ fontWeight: "bold", color: "red" }}>*</span></p>
                            <div style={{ width: "100%" }}>
                                <div style={{ width: "80%", display: "inline-block" }} >
                                    <CustomTextField
                                        value={otp}
                                        onChange={setOtp}
                                        placeholder={"6 digits verification code"}
                                        type="text"
                                        id="password"
                                        disabled={isLoggingIn}
                                        customSX={{
                                            padding: "6px 15px",
                                            marginBottom: "10px"
                                        }}
                                    />
                                </div>
                                <div style={{ width: "20%", display: "inline-block" }} >
                                    <CustomButton
                                        buttonLabel={"Send"}
                                        onClick={() => handleSendOTP()}
                                        type="submit"
                                        disabled={format ? true : false}
                                        customSX={{
                                            width: "100%",
                                            padding: "6px 15px",
                                            color: ConstantStyles.black,
                                            border: ConstantStyles['default-border'],
                                            backgroundColor: ConstantStyles.white,
                                            verticalAlign: "top",
                                            "&:hover": {
                                                backgroundColor: ConstantStyles.primary,
                                                color: ConstantStyles.white,
                                                boxShadow: "none"
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                            <br />
                            <CustomButton
                                buttonLabel={isLoggingIn ? "Processing..." : "Login / Register"}
                                onClick={() => handleSubmit()}
                                type="submit"
                                disabled={number === "" || otp === "" ? true : false}
                                customSX={{
                                    width: "100%",
                                    padding: "6px 15px",
                                    color: ConstantStyles.white
                                }}
                            />
                        </>
                        :

                        <>
                            <p style={{ fontSize: "16px", marginBottom: "5px" }}>Phone / Email <span style={{ fontWeight: "bold", color: "red" }}>*</span></p>
                            <CustomTextField
                                value={userName}
                                onKeyPress={handleKeypress}
                                onChange={(e) => {
                                    setUsername(e)
                                    formatChecker(e)
                                }}
                                placeholder={"Enter Phone / Email"}
                                type="text"
                                id="email"
                                disabled={isLoggingIn}
                                customSX={{
                                    padding: "6px 15px",
                                    marginBottom: format ? "0px" : "20px",
                                    border: format ? "1px solid red" : "1px solid #D0D0D0"
                                }}
                            />
                            <small style={{ fontSize: "12px", color: "red" }}>{format}</small>
                            <p style={{ fontSize: "16px", marginBottom: "5px" }}>Password <span style={{ fontWeight: "bold", color: "red" }}>*</span></p>
                            <div style={{ width: "100%" }}>
                                <div style={{ width: "100%", display: "inline-block" }} >
                                    <CustomTextField
                                        value={password}
                                        onKeyPress={handleKeypress}
                                        onChange={sePassword}
                                        placeholder={"Password"}
                                        type="password"
                                        id="password"
                                        disabled={isLoggingIn}
                                        customSX={{
                                            padding: "6px 15px",
                                            marginBottom: "10px"
                                        }}
                                    />
                                </div>
                            </div>
                            <br />
                            <CustomButton
                                buttonLabel={isLoggingIn ? "Processing..." : "Login "}
                                onClick={() => handleLoginPassword()}
                                type="submit"
                                disabled={userName === "" || password === "" ? true : false}
                                customSX={{
                                    width: "100%",
                                    padding: "6px 15px",
                                    color: ConstantStyles.white
                                }}
                            />
                        </>

                    }
                    <br />
                    <br />
                    <p style={{ textAlign: "center" }}>
                        By login or register, you are confirming that you are above 21 years old, and agreed to our <br /><a href="#"><b>Terms of use</b></a> and <a href="#"><b>Privacy policy</b></a>
                    </p>
                </div>
            </div>
        </div>
    )


}


export default Login;