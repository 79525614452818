import { useState, useEffect, useMemo } from "react";
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from '../../../utils/GlobalStates';
import ConstantStyles from "../../../styles/ConstantStyles.json";

import KYC from "./Section/KYC";
import {
    Box
} from '@mui/material';

const AccountSettings = () => {
    const globalStates = useHookState(GlobalStates);
    const [activeTab, setActiveTab] = useState("0");
    useEffect(() => {
        if (!localStorage.getItem("username")) {
            window.location.href = "/login";
        }
    }, []);
    
    const handleChange = (event, newValue) => {
        setActiveTab(event.target.id);
    };


    return (
        <div style={{ backgroundColor: ConstantStyles['light-grey'] }}>
            <Box sx={{
                backgroundColor: ConstantStyles['light-grey'],
                margin: "0 auto",
                maxWidth: "1440px",
                paddingLeft: { xs: "0px", sm: "0px", md: "220px" },
                minHeight: "850px",
                paddingTop: "20px",
                paddingBottom: "140px"
            }} >

                {localStorage.getItem("username") ?
                    <KYC />
                    :

                    <div style={{
                        backgroundColor: "#fff",
                        position: "fixed",
                        width: "100%",
                        height: "100%",
                        left: "0px",
                        zIndex: "1201",
                        top: "0px"
                    }}></div>
                }
            </Box>
        </div>
    )


}


export default AccountSettings;