import { useState as useHookState } from "@hookstate/core";
import PropTypes from 'prop-types';
import { 
    Tooltip,
    IconButton
} from "@mui/material";
import { Close } from "@mui/icons-material";
import CustomButton from "./CustomButton";
import ConstantStyles from "../../styles/ConstantStyles.json";
import GlobalStates from "../../utils/GlobalStates";
import { useEffect , useRef, useState } from "react";
import { GetStreamToken } from "../../utils/Endpoints";

function CustomIFrame(props) {
    const [streamUrl, setStreamUrl] = useState(false);
    const [height, setHeight] = useState(false);
    const [width, setWidth] = useState(false);
    const iframeRef = useRef();




    // iframeRef


    useEffect(()=>{

        const getParent = ()=>iframeRef.current.parentElement.parentElement;
        const onResize = (e)=>{
            let parent = getParent();
            let {height, width} = parent.getBoundingClientRect();
          
            setHeight(height);
            setWidth(width);
    
        };

        onResize();
    
        window.addEventListener('resize',onResize);


        GetStreamToken().then(res=>{
            if(res.data.status){
                console.log(res.data.data.token);
            };


            let baseUrl = 'https://dev-streamer.megabingo.ph';
            if(window.location.origin.includes('localhost')){
                baseUrl = 'http://localhost:7301';
            };

            let path = `/active-session`


            setStreamUrl(`${baseUrl}${path}`);
        })


        return ()=>{
            window.removeEventListener('resize',onResize);
        }
    },[]);

    return (
        <>
            <div style={{width:width, height:height}}>
                <iframe ref={iframeRef} style={{width:'100%', height: '100%'}} src={streamUrl} title="A nice piano piece to listen to while smelling the spring scent" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </>
    )
}



export default CustomIFrame;